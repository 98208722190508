const data = [
  {
    id: 1,
    grade: '1',
    created_at: '2021-01-31 17:20:56',
    updated_at: '2021-01-31 17:20:56',
  },
  {
    id: 2,
    grade: '2',
    created_at: '2021-01-31 17:20:59',
    updated_at: '2021-01-31 17:20:59',
  },
  {
    id: 3,
    grade: '3',
    created_at: '2021-01-31 17:21:02',
    updated_at: '2021-01-31 17:21:02',
  },
  {
    id: 4,
    grade: '4',
    created_at: '2021-01-31 17:21:06',
    updated_at: '2021-01-31 17:21:06',
  },
]

export default data
