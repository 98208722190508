/**
 *
 * UploadImage
 *
 */
import React, { useCallback, useRef, memo } from 'react'
import { Box } from '@material-ui/core'
import Cropper from 'react-easy-crop'
import { Area } from 'react-easy-crop/types'
import { Modal, Button } from '../index'
import { Text } from '../Text'

interface Props {
  /**
   * select image after crop
   */
  onSelectImage: (file: any) => void

  disable?: boolean

  aspect?: number

  text?: string
}

interface Crop {
  x: number
  y: number
}

export const UploadImage = memo((props: Props) => {
  const { onSelectImage, disable, aspect, text } = props
  const [image, setImage] = React.useState('')
  const [imageSize, setImageSize] = React.useState(0)
  const [croppedArea, setCroppedArea] = React.useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [errorMsg, setErrorMsg] = React.useState('')
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedArea(croppedAreaPixels)
    },
    [],
  )

  const onSelectFile = (e) => {
    if (e.target?.files?.length) {
      let path = URL.createObjectURL(e.target.files[0])
      if (e.target.files[0].size >= 2000000) {
        setErrorMsg('profileContainer.imageErrorMsg')
      } else {
        setImageSize(Number(e.target.files[0].size))
      }
      setImage(path)
    }
  }

  const onCloseModal = () => {
    if (fileInputRef.current) fileInputRef.current.value = ''
    setImage('')
    setErrorMsg('')
    onCropComplete(
      { width: 0, height: 0, x: 0, y: 0 },
      { width: 0, height: 0, x: 0, y: 0 },
    )
  }

  const createImage = (url): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const imageElement: HTMLImageElement = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx: CanvasRenderingContext2D =
      canvas.getContext('2d') ?? new CanvasRenderingContext2D()

    const maxSize = Math.max(imageElement.width, imageElement.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
      imageElement,
      safeArea / 2 - imageElement.width * 0.5,
      safeArea / 2 - imageElement.height * 0.5,
    )

    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + imageElement.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + imageElement.height * 0.5 - pixelCrop.y,
    )

    // As Base64 string
    // return canvas.toDataURL("image/jpeg");
    return canvas
  }

  const onCropSelect = async (imageSrc, crop) => {
    if (!crop || !imageSrc) {
      return
    }
    const canvas = await getCroppedImg(imageSrc, crop)
    let isImageBigger = imageSize > 1000000
    canvas.toBlob(
      (blob) => {
        if (!blob) return
        let file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' })
        onSelectImage(file)
      },
      'image/jpeg',
      isImageBigger ? 0.5 : 0.95,
    )
    onCloseModal()
  }

  return (
    <React.Fragment>
      <div>
        <input
          type='file'
          accept='image/*'
          onChange={onSelectFile}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
      </div>
      <Button
        onClick={() => fileInputRef.current?.click()}
        text={{ text: text ? text : 'Add Banner' }}
        size='medium'
        disabled={disable ?? false}
        variant='contained'
        color='primary'
        spacingProps={{
          marginTop: 2,
        }}
      />
      <Modal
        open={!!image}
        titleText={{ text: 'Crop your image before upload' }}
        onDismiss={onCloseModal}
        onSubmit={() => onCropSelect(image, croppedArea)}
        submitDisabled={!!errorMsg}
        spacingProps={{
          marginHorizontal: 2,
        }}
        displayText="Crop"
      >
        <Box>
          <Box style={{ height: '60vh', position: 'relative' }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={aspect ? aspect : 1.5 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Box justifyContent='center'>
            {!!errorMsg && <Text variant='body2' tx={errorMsg} color='error' />}
          </Box>
          {/* <Box className='slider'>
            <Slider
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, value) => setZoom(Number(value))}
            />
          </Box> */}
        </Box>
      </Modal>
    </React.Fragment>
  )
})
