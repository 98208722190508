import {observable} from 'mobx'
import {Model} from './model'

// Course/subject
export class Subject extends Model {
  @observable private readonly name: string
  @observable private readonly description: string
  @observable private readonly imageURL: string

  constructor(subject: any) {
    super(subject)
    this.name = subject?.name ?? ''
    this.description = subject?.description ?? ''
    this.imageURL = subject?.imageURL ?? ''
  }

  getName = (): string => {
    return this.name
  }

  getDescription = (): string => {
    return this.description
  }

  getImageURL = (): string => {
    return this.imageURL
  }
}
