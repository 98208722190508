import {List} from './list'
import {WorldMembers} from './world-members'

export class WorldMembersList extends List<WorldMembers> {
  constructor(rawData?: any) {
    super(rawData, WorldMembers)
  }

  getAllMembers(): WorldMembersList {
    const list = new WorldMembersList()
    return list
  }
}
