import {List} from './list'
import {Experience} from './experience'
import {Status} from 'app/types'

export class ExperienceList extends List<Experience> {
  constructor(rawData?: any) {
    super(rawData, Experience)
  }

  getApprovedExperiences(): ExperienceList {
    const list = new ExperienceList()
    list.addItems(this.filter((item) => item.getStatus() === Status.APPROVED))
    return list
  }

  getAllExperiences(): ExperienceList {
    const list = new ExperienceList()
    list.addItems(
      this.filter(
        (item) => item.isApproved() || item.isPending() || item.isRejected(),
      ),
    )
    return list
  }
}
