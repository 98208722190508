const data = [
  {
    id: 25,
    name: 'May batch',
    course_id: 16,
    start_time: 1620174600,
    additional_info: null,
    status: 1,
    registration_count: 1,
    total_earning: 1234,
    earning_after_deduction: 1111,
    course: {
      id: 16,
      owner_id: 25,
      title: 'Growing your social media brand',
      duration: '30 minutes',
      featured_image:
        'https://api.edvolve.in/media/ca0945e971cf35cedd787c3b31da3dccfileName.jpg',
      mentor: {
        id: 25,
        first_name: 'Maria',
        last_name: 'Thom',
        avatar_url:
          'https://api.edvolve.in/media/794a3939ddaae459164150dbabd8259efileName.jpg',
        about_me:
          "Hey kids! I'm a person with diverse interests but mostly stuff that involves interactions. I started my journey like any other Psychology student & slowly realised my love for not just being a practicing counsellor but also an educator in the field because I truly believe that we all collectively need to empathise with one another. \n\nLot of our daily habits are impacted by the way we think and the way we respond to situations. I specially have experience interacting with younger kids to help them with concerns such as depression, anxiety, loss/grief, stress, loneliness etc. I believe in employing a comprehensive approach including person-centered, existential, rational emotive behaviorial therapy.\n\nI also run a mental health wellbeing community here, where I help adults going through anxiety and depression come out of it through group activities and wellness sessions.\n\nSo, see you all in my class!",
        city_id: 439,
      },
    },
  },
  {
    id: 29,
    name: 'New June',
    course_id: 17,
    start_time: 1623976200,
    additional_info: null,
    status: 1,
    registration_count: 1,
    total_earning: 1234,
    earning_after_deduction: 1111,
    course: {
      id: 17,
      owner_id: 25,
      title: 'Learn how Data Science works!',
      duration: '75 minutes',
      featured_image:
        'https://api.edvolve.in/media/4d6400f6126ecfc944250f111f89e55ffileName.jpg',
      mentor: {
        id: 25,
        first_name: 'Maria',
        last_name: 'Thom',
        avatar_url:
          'https://api.edvolve.in/media/794a3939ddaae459164150dbabd8259efileName.jpg',
        about_me:
          "Hey kids! I'm a person with diverse interests but mostly stuff that involves interactions. I started my journey like any other Psychology student & slowly realised my love for not just being a practicing counsellor but also an educator in the field because I truly believe that we all collectively need to empathise with one another. \n\nLot of our daily habits are impacted by the way we think and the way we respond to situations. I specially have experience interacting with younger kids to help them with concerns such as depression, anxiety, loss/grief, stress, loneliness etc. I believe in employing a comprehensive approach including person-centered, existential, rational emotive behaviorial therapy.\n\nI also run a mental health wellbeing community here, where I help adults going through anxiety and depression come out of it through group activities and wellness sessions.\n\nSo, see you all in my class!",
        city_id: 439,
      },
    },
  },
]

export default data
