const data = [
  {
    id: 51,
    title: 'test',
    date: '20-12-2020',
    start_time_ts: '2020-12-20 18:30:00',
    end_time_ts: '2020-12-20 18:40:00',
    start_time: 1608469200,
    end_time: 1608469800,
    description: 'test',
    owner_id: 2,
    cities: [
      {
        id: 1,
        name: 'Mumbai',
        country: 'India',
        laravel_through_key: 51,
      },
      {
        id: 2,
        name: 'Bengaluru',
        country: 'India',
        laravel_through_key: 51,
      },
    ],
    classrooms: [
      {
        id: 1,
        name: '6-A',
        school_id: 4,
        laravel_through_key: 51,
        institution: {
          id: 4,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 2,
        name: '9-B',
        school_id: 6,
        laravel_through_key: 51,
        institution: {
          id: 6,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 3,
        name: '10-A',
        school_id: 5,
        laravel_through_key: 51,
        institution: {
          id: 5,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
    ],
    institutions: [
      {
        id: 4,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 51,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
      {
        id: 5,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 51,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
    ],
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 51,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 51,
      },
    ],
    notes: [],
  },
  {
    id: 52,
    title: 'test',
    date: '20-12-2020',
    start_time_ts: '2020-12-20 18:30:00',
    end_time_ts: '2020-12-20 18:40:00',
    start_time: 1608469200,
    end_time: 1608469800,
    description: 'test',
    owner_id: 2,
    cities: [
      {
        id: 1,
        name: 'Mumbai',
        country: 'India',
        laravel_through_key: 52,
      },
      {
        id: 2,
        name: 'Bengaluru',
        country: 'India',
        laravel_through_key: 52,
      },
    ],
    classrooms: [
      {
        id: 1,
        name: '6-A',
        school_id: 4,
        laravel_through_key: 52,
        institution: {
          id: 4,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 2,
        name: '9-B',
        school_id: 6,
        laravel_through_key: 52,
        institution: {
          id: 6,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 3,
        name: '10-A',
        school_id: 5,
        laravel_through_key: 52,
        institution: {
          id: 5,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
    ],
    institutions: [
      {
        id: 4,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 52,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
      {
        id: 5,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 52,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
    ],
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 52,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 52,
      },
    ],
    notes: [],
  },
  {
    id: 53,
    title: 'test',
    date: '20-12-2020',
    start_time_ts: '2020-12-20 18:30:00',
    end_time_ts: '2020-12-20 18:40:00',
    start_time: 1608469200,
    end_time: 1608469800,
    description: 'test',
    owner_id: 2,
    cities: [
      {
        id: 1,
        name: 'Mumbai',
        country: 'India',
        laravel_through_key: 53,
      },
      {
        id: 2,
        name: 'Bengaluru',
        country: 'India',
        laravel_through_key: 53,
      },
    ],
    classrooms: [
      {
        id: 1,
        name: '6-A',
        school_id: 4,
        laravel_through_key: 53,
        institution: {
          id: 4,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 2,
        name: '9-B',
        school_id: 6,
        laravel_through_key: 53,
        institution: {
          id: 6,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 3,
        name: '10-A',
        school_id: 5,
        laravel_through_key: 53,
        institution: {
          id: 5,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
    ],
    institutions: [
      {
        id: 4,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 53,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
      {
        id: 5,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 53,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
    ],
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 53,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 53,
      },
    ],
    notes: [],
  },
  {
    id: 56,
    title: 'test',
    date: '20-12-2020',
    start_time_ts: '2020-12-20 18:30:00',
    end_time_ts: '2020-12-20 18:40:00',
    start_time: 1608469200,
    end_time: 1608469800,
    description: 'test',
    owner_id: 2,
    cities: [
      {
        id: 1,
        name: 'Mumbai',
        country: 'India',
        laravel_through_key: 56,
      },
      {
        id: 2,
        name: 'Bengaluru',
        country: 'India',
        laravel_through_key: 56,
      },
    ],
    classrooms: [
      {
        id: 1,
        name: '6-A',
        school_id: 4,
        laravel_through_key: 56,
        institution: {
          id: 4,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 2,
        name: '9-B',
        school_id: 6,
        laravel_through_key: 56,
        institution: {
          id: 6,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 3,
        name: '10-A',
        school_id: 5,
        laravel_through_key: 56,
        institution: {
          id: 5,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
    ],
    institutions: [
      {
        id: 4,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 56,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
      {
        id: 5,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 56,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
    ],
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 56,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 56,
      },
    ],
    notes: [
      {
        id: 5,
        notes: 'First Note',
        session_id: 56,
      },
      {
        id: 6,
        notes: 'Second Note',
        session_id: 56,
      },
    ],
  },
  {
    id: 63,
    title: 'test',
    date: '20-12-2020',
    start_time_ts: '2020-12-20 18:30:00',
    end_time_ts: '2020-12-20 18:40:00',
    start_time: 1608469200,
    end_time: 1608469800,
    description: 'test',
    owner_id: 2,
    cities: [
      {
        id: 1,
        name: 'Mumbai',
        country: 'India',
        laravel_through_key: 63,
      },
      {
        id: 2,
        name: 'Bengaluru',
        country: 'India',
        laravel_through_key: 63,
      },
    ],
    classrooms: [
      {
        id: 1,
        name: '6-A',
        school_id: 4,
        laravel_through_key: 63,
        institution: {
          id: 4,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 2,
        name: '9-B',
        school_id: 6,
        laravel_through_key: 63,
        institution: {
          id: 6,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 3,
        name: '10-A',
        school_id: 5,
        laravel_through_key: 63,
        institution: {
          id: 5,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
    ],
    institutions: [
      {
        id: 4,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 63,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
      {
        id: 5,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 63,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
    ],
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 63,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 63,
      },
    ],
    notes: [
      {
        id: 19,
        notes: 'First Note',
        session_id: 63,
      },
      {
        id: 20,
        notes: 'Second Note',
        session_id: 63,
      },
    ],
  },
  {
    id: 64,
    title: 'test',
    date: '20-12-2020',
    start_time_ts: '2020-12-20 18:30:00',
    end_time_ts: '2020-12-20 18:40:00',
    start_time: 1546300800,
    end_time: 1546300800,
    description: 'test',
    owner_id: 2,
    cities: [
      {
        id: 1,
        name: 'Mumbai',
        country: 'India',
        laravel_through_key: 64,
      },
      {
        id: 2,
        name: 'Bengaluru',
        country: 'India',
        laravel_through_key: 64,
      },
    ],
    classrooms: [
      {
        id: 1,
        name: '6-A',
        school_id: 4,
        laravel_through_key: 64,
        institution: {
          id: 4,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 2,
        name: '9-B',
        school_id: 6,
        laravel_through_key: 64,
        institution: {
          id: 6,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
      {
        id: 3,
        name: '10-A',
        school_id: 5,
        laravel_through_key: 64,
        institution: {
          id: 5,
          name: 'test',
          address: 'test test',
          city_id: 2,
          city: {
            id: 2,
            name: 'Bengaluru',
            country: 'India',
          },
        },
      },
    ],
    institutions: [
      {
        id: 4,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 64,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
      {
        id: 5,
        name: 'test',
        address: 'test test',
        city_id: 2,
        laravel_through_key: 64,
        city: {
          id: 2,
          name: 'Bengaluru',
          country: 'India',
        },
      },
    ],
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 64,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 64,
      },
    ],
    notes: [
      {
        id: 21,
        notes: 'First Note',
        session_id: 64,
      },
      {
        id: 22,
        notes: 'Second Note',
        session_id: 64,
      },
    ],
  },
]
export default data
