import {observable} from 'mobx'
import {Transaction} from './transaction'
import {Experience} from './experience'

export class ExperienceTransaction extends Transaction {
  @observable readonly experienceId: number
  @observable readonly experience: Experience

  constructor(transactionExperience: any) {
    super(transactionExperience)

    this.experienceId = transactionExperience?.experience_id
    this.experience = new Experience(transactionExperience?.experience)
  }
}
