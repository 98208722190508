/**
 *
 * Institution
 *
 */
import React, {memo} from 'react'
import {Box, Paper} from '@material-ui/core'
import {Text} from '../Text'
import {Institution} from '../../models'

export const InstitutionComponent: React.FunctionComponent<{
  institution: Institution
}> = memo(({institution}: {institution: Institution}) => {
  return (
    <Paper>
      <Box padding={2}>
        <Text
          bold
          uppercase
          variant='body2'
          tx='common.name'
          color='secondary'
        />
        <Text
          variant='body2'
          text={institution.getName()}
          color='textSecondary'
        />
        <Box pt={2}>
          <Text
            bold
            uppercase
            variant='body2'
            tx='common.address'
            color='secondary'
          />
          <Text
            variant='body2'
            text={institution.getAddress()}
            color='textSecondary'
          />
        </Box>
      </Box>
    </Paper>
  )
})
