import {Model} from './model'
import {observable} from 'mobx'

export class City extends Model {
  @observable private readonly name: string
  @observable private readonly country: string

  constructor(city: any) {
    super(city)
    this.name = city?.name ?? ''
    this.country = city?.country ?? ''
  }

  getName(): string {
    return this.name
  }

  getCountry(): string {
    return this.country
  }
}
