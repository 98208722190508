import _ from 'lodash'
import {observable} from 'mobx'

export class Attachment {
  @observable readonly id: string
  @observable private readonly name: string
  @observable private readonly description: string
  @observable private readonly url: string

  constructor(attachment: any) {
    this.id = _.get(attachment, 'id', '')
    this.name = _.get(attachment, 'name', '')
    this.description = _.get(attachment, 'description', '')
    this.url = _.get(attachment, 'url', '')
  }

  getName = (): string => {
    return this.name
  }

  getDescription = (): string => {
    return this.description
  }

  getUrl = (): string => {
    return this.url
  }
}
