/**
 *
 * Alert
 *
 */
import React, {memo} from 'react'
import {Button} from '../'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {TextProps} from '../Text'

interface Props {
  label: TextProps
  onAgree: () => void
  onDisagree?: () => void
  dialog: string
  title?: string
  variant?: 'text' | 'outlined' | 'contained' | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined
}

export const Alert = memo(
  ({label, onAgree, onDisagree, dialog, title, variant, color}: Props) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <div>
        <Button
          variant={variant ?? 'contained'}
          color={color ?? 'primary'}
          onClick={handleClickOpen}
          text={label}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{title ?? 'Alert'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {dialog}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onDisagree ?? handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={onAgree} color='primary' autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  },
)
