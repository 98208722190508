const data = [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
    name: 'Candor International School Bangalore (IB IGCSE)',
    address:
      'Koppa-harapanhalli Road, Hullahalli, Off, Bannerghatta Main Rd, near Electronic City, Bengaluru, Karnataka 560105',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa2',
    name: 'Candor International School Bangalore (IB IGCSE)',
    address:
      'Koppa-harapanhalli Road, Hullahalli, Off, Bannerghatta Main Rd, near Electronic City, Bengaluru, Karnataka 560105',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa3',
    name: 'Candor International School Bangalore (IB IGCSE)',
    address:
      'Koppa-harapanhalli Road, Hullahalli, Off, Bannerghatta Main Rd, near Electronic City, Bengaluru, Karnataka 560105',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa4',
    name: 'Candor International School Bangalore (IB IGCSE)',
    address:
      'Koppa-harapanhalli Road, Hullahalli, Off, Bannerghatta Main Rd, near Electronic City, Bengaluru, Karnataka 560105',
  },
]

export default data
