/**
 *
 * Asynchronously loads the component for Mentors
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Mentors = lazyLoad(
  () => import('./index'),
  (module) => module.Mentors,
)
