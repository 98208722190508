/**
 *
 * Input
 *
 */
import React, {memo} from 'react'
import {
  TextField,
  TextFieldProps,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {Spacing, SpacingProps} from '../Spacing'
import {Text} from '../Text'

interface Props {
  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps

  /**
   * Input height
   */
  height?: number

  /**
   * Input width
   */
  width?: number

  /**
   * Input max height
   */
  maxHeight?: number

  /**
   * Input max width
   */
  maxWidth?: number

  /**
   * Input min height
   */
  minHeight?: number

  /**
   * Input min width
   */
  minWidth?: number

  /**
   * if 'hasError' is true then input field will become red
   */
  hasError?: boolean

  /**
   * I18n key of the error message
   */
  errorMessage?: string
}

const styles = (theme: Theme) => ({
  input: {
    height: (props) => props.height,
    maxHeight: (props) => props.maxHeight,
    minHeight: (props) => props.minHeight,
    width: (props) => props.width,
    maxWidth: (props) => props.maxWidth,
    minWidth: (props) => props.minWidth,
  },
})

export const Input: React.FunctionComponent<Props & TextFieldProps> = memo(
  withStyles(styles)(
    (props: Props & TextFieldProps & WithStyles<typeof styles>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {spacingProps, errorMessage, hasError, classes, ...rest} = props

      return (
        <Spacing {...spacingProps}>
          <TextField className={classes.input} error={hasError} {...rest} />
          {!!errorMessage && (
            <Text variant='body2' tx={errorMessage} color='error' />
          )}
        </Spacing>
      )
    },
  ),
)
