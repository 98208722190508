import {action, computed, observable} from 'mobx'
import {
  CityList,
  ClassroomList,
  InstitutionList,
  UserList,
  WebinarList,
  SubjectList,
  QuestionList,
  GradeList,
  LanguageList,
  ExperienceList,
  ScheduleList,
  CourseList,
  BatchList,
  ClassList,
  CourseTransactionList,
  ExperienceTransactionList,
  WorldsList,
  WorldsTransactionList,
  WorldsLibraryList,
  WorldMembersList,
  WorldActivityFeedList,
} from '../../models'
import {AppStore} from './app.store'
import {StoreImpl} from '../store/store.impl'
import {
  citiesFixture,
  classroomsFixture,
  subjectsFixture,
  institutionsFixture,
  webinarFixture,
  usersFixture,
  questionsFixture,
  gradesFixture,
  languagesFixture,
  classesFixture,
  coursesTransactionFixture,
  experienceTransactionFixture,
  worldsTransactionFixture,
} from '../../../utils/fixtures'
import { UserFeedList } from 'app/models/user-feed-list'

export class AppStoreImpl extends StoreImpl implements AppStore {
  static USE_FIXTURES = false
  // @ts-ignore
  @observable institutionList: InstitutionList
  // @ts-ignore
  @observable webinarList: WebinarList
  // @ts-ignore
  @observable questionList: QuestionList
  // @ts-ignore
  @observable subjectList: SubjectList
  // @ts-ignore
  @observable classroomList: ClassroomList
  // @ts-ignore
  @observable userList: UserList
  // @ts-ignore
  @observable cityList: CityList
  // @ts-ignore
  @observable languageList: LanguageList
  // @ts-ignore
  @observable gradeList: GradeList
  // @ts-ignore
  @observable experienceList: ExperienceList
  // @ts-ignore
  @observable courseList: CourseList
  // @ts-ignore
  @observable scheduleList: ScheduleList
  // @ts-ignore
  @observable batchList: BatchList
  // @ts-ignore
  @observable classList: ClassList
  // @ts-ignore
  @observable coursesTransactionList: CourseTransactionList
  // @ts-ignore
  @observable experienceTransactionList: ExperienceTransactionList
  // @ts-ignore
  @observable worldsList: WorldsList
  // @ts-ignore
  @observable worldsTransactionList: WorldsTransactionList
  // @ts-ignore
  @observable worldsLibraryList: WorldsLibraryList
  // @ts-ignore
  @observable worldMembersList: WorldMembersList
  // @ts-ignore
  @observable worldActivityFeedList: WorldActivityFeedList
  // @ts-ignore
  @observable userFeedList: UserFeedList

  constructor() {
    super()
    this.resetStore()

    if (AppStoreImpl.USE_FIXTURES && process.env.NODE_ENV !== 'production') {
      this.resetStoreWithFixtures()
    }
  }

  @action
  resetStore() {
    this.institutionList = new InstitutionList()
    this.classroomList = new ClassroomList()
    this.userList = new UserList()
    this.webinarList = new WebinarList()
    this.questionList = new QuestionList()
    this.subjectList = new SubjectList()
    this.cityList = new CityList()
    this.languageList = new LanguageList()
    this.gradeList = new GradeList()
    this.courseList = new CourseList()
    this.experienceList = new ExperienceList()
    this.batchList = new BatchList()
    this.scheduleList = new ScheduleList()
    this.classList = new ClassList()
    this.coursesTransactionList = new CourseTransactionList()
    this.experienceTransactionList = new ExperienceTransactionList()
    this.worldsList = new WorldsList()
    this.worldsTransactionList = new WorldsTransactionList()
    this.worldsLibraryList = new WorldsLibraryList()
    this.worldMembersList = new WorldMembersList()
    this.worldActivityFeedList = new WorldActivityFeedList()
    this.userFeedList = new UserFeedList()
  }

  @action
  private resetStoreWithFixtures() {
    this.institutionList = new InstitutionList(institutionsFixture)
    this.webinarList = new WebinarList(webinarFixture)
    this.questionList = new QuestionList(questionsFixture)
    this.subjectList = new SubjectList(subjectsFixture)
    this.classroomList = new ClassroomList(classroomsFixture)
    this.userList = new UserList(usersFixture)
    this.languageList = new LanguageList(languagesFixture)
    this.cityList = new CityList(citiesFixture)
    this.gradeList = new GradeList(gradesFixture)
    this.classList = new ClassList(classesFixture)
    this.coursesTransactionList = new CourseTransactionList(
      coursesTransactionFixture,
    )
    this.experienceTransactionList = new ExperienceTransactionList(
      experienceTransactionFixture,
    )
    this.worldsTransactionList = new WorldsTransactionList(
      worldsTransactionFixture,
    )
    // TODO: add fixtures
  }

  @computed
  get mentorList(): UserList {
    return this.userList.getFilteredList(
      (item) => item.isMentor(),
      new UserList(),
    )
  }
}
