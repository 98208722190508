/**
 *
 * WorldCard
 *
 */
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { GroupSharp } from '@material-ui/icons'
import { Worlds } from 'app/models'
import { Status } from 'app/types'
import { observer } from 'mobx-react'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Button } from '../index'
import { useHistory } from 'react-router-dom'

interface WorldCardProps {
  /**
   * details of the World
   */
  worlds: Worlds

  /**
   * redirect to edit page
   */
  handleEdit: () => void
}

const styles = () => ({
  root: {
    // width: 360,
    minHeight: 190,
  },
  icon: {
    fontSize: 23,
    marginBottom: 4,
    paddingRight: 2,
  },
  action: {
    justifyContent: 'space-between',
    padding: 16,
    paddingBottom: 8,
  },
  actionEnd: {
    justifyContent: 'flex-end',
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  status: (props) => ({
    color:
      props.worlds.getStatus() === Status.APPROVED
        ? 'green'
        : props.worlds.getStatus() === Status.PENDING
          ? '#FF9100'
          : 'red',
  }),
})

export const WorldCard = memo(
  withStyles(styles)(
    observer(
      ({
        classes,
        worlds,
        handleEdit,
      }: WorldCardProps & WithStyles<typeof styles>) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { t } = useTranslation()
        const history = useHistory()

        const handleView = () => {
          history.push(
            `/dashboard/worlds/${worlds.getId()}/activity`,
            worlds.getId(),
          )
        }

        return (
          <>
            <Card className={classes.root}>
              <CardContent>
                <Text
                  text={worlds.getTitle()}
                  variant='h6'
                  style={{ height: "70px" }}
                />
                <div className={classes.text}>
                  <Typography color='textSecondary' variant='subtitle1'>
                    <GroupSharp className={classes.icon} />
                    {`${worlds?.membersCount} Members`}
                  </Typography>
                  <Text
                    tx={
                      worlds.getStatus() === Status.APPROVED
                        ? 'worldCard.approved'
                        : worlds.getStatus() === Status.PENDING
                          ? 'worldCard.inReview'
                          : 'worldCard.rejected'
                    }
                    variant='button'
                    className={classes.status}
                  />
                </div>
              </CardContent>
              {worlds.getStatus() == Status.APPROVED && (
                <CardActions className={classes.action}>
                  <Button
                    color='primary'
                    onClick={handleView}
                    style={{
                      background: "#1D9929",
                      color: "#fff",
                      fontSize: "9px",
                    }}
                  >
                    {t('worldCard.enter')}
                  </Button>

                  <Button
                    color='primary'
                    onClick={() =>
                      window
                        .open(
                          `https://app.helloworlds.co/worlds/${worlds.getNumericId()}`,
                          '_blank',
                        )
                        ?.focus()
                    }
                    style={{
                      border: "1px solid #1D9929",
                      color: "#1D9929",
                      fontSize: "9px",
                    }}
                  >
                    {t('worldCard.preview')}
                  </Button>

                  <Button
                    color='primary'
                    onClick={handleEdit}
                    style={{
                      border: "1px solid #1D9929",
                      color: "#1D9929",
                      fontSize: "9px",
                    }}
                  >
                    Edit Content
                  </Button>
                </CardActions>
              )}

              {worlds.getStatus() !== Status.APPROVED && (
                <CardActions className={classes.actionEnd}>
                  <Button
                    color='primary'
                    onClick={handleEdit}
                    style={{
                      border: "1px solid #1D9929",
                      color: "#1D9929",
                      marginTop: "8px",
                      fontSize: "9px",
                    }}
                  >
                    Edit Content
                  </Button>
                </CardActions>
              )}
            </Card>
          </>
        )
      },
    ),
  ),
)
