/**
 *
 * Asynchronously loads the component for CreateOnlineExp
 *
 */

import {lazyLoad} from 'utils/loadable'

export const CreateOnlineExp = lazyLoad(
  () => import('./index'),
  (module) => module.CreateOnlineExp,
)
