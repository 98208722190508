/**
 *
 * File Upload
 *
 */
import React, {useRef, memo} from 'react'
import {Button} from '../index'
import {useNotifier} from '../../contexts'

interface Props {
  onSelectImage: (file: any) => void

  disable?: boolean

  text?: string
}

export const UploadFile = memo((props: Props) => {
  const {onSelectImage, disable, text} = props
  const [errorMsg, setErrorMsg] = React.useState('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const notifier = useNotifier()

  const onSelectFile = (e) => {
    if (e.target?.files?.length) {
      if (e.target.files[0].size >= 2000000) {
        setErrorMsg('profileContainer.imageErrorMsg')
        notifier.error('File size should be less than 2MB')
      } else if (
        e.target.files[0].type !== 'image/jpeg' &&
        e.target.files[0].type !== 'image/jpg' &&
        e.target.files[0].type !== 'image/png' &&
        e.target.files[0].type !== 'application/pdf'
      ) {
        setErrorMsg('profileContainer.imageErrorMsg')
        notifier.error('Only jpeg, png and pdf files are allowed')
      } else {
        onSelectImage(e.target.files[0])
      }
    } else {
      setErrorMsg('profileContainer.imageErrorMsg')
      notifier.error('Something went wrong! Please try again')
    }
  }

  return (
    <React.Fragment>
      <div>
        <input
          type='file'
          accept='image/jpeg, image/png, application/pdf'
          onChange={onSelectFile}
          ref={fileInputRef}
          style={{display: 'none'}}
        />
      </div>
      <Button
        onClick={() => fileInputRef.current?.click()}
        text={{text: text ? text : 'Add File'}}
        size='medium'
        disabled={disable ?? false}
        variant='contained'
        color='primary'
        spacingProps={{
          marginTop: 2,
        }}
      />
    </React.Fragment>
  )
})
