const data = [
  {
    id: 1,
    session_id: 98,
    question: 'What is a recording?',
    answer: null,
    created_at: '2021-01-14 14:08:57',
    updated_at: '2021-01-14 14:08:57',
  },
  {
    id: 2,
    session_id: 98,
    question: 'What is a recording?',
    answer: 'Recording is a record.',
    created_at: '2021-01-14 14:17:11',
    updated_at: '2021-01-14 14:20:25',
  },
]

export default data
