import _ from 'lodash'
import {observable} from 'mobx'
import {Model} from './model'

export class WorldMembers extends Model {
  @observable readonly studentId: string
  @observable readonly avatar: string
  @observable readonly firstName: string
  @observable readonly lastName: string
  @observable readonly age: string
  @observable readonly grade: number
  @observable readonly city: string
  @observable readonly activeSince: string
  @observable readonly UUID: string

  constructor(worldMembers: any) {
    super(worldMembers)
    this.studentId = worldMembers?.student_id ?? ''
    this.avatar = worldMembers?.uuid.student.avatar_url ?? ''
    this.firstName = worldMembers?.uuid.student.first_name ?? ''
    this.lastName = worldMembers?.uuid.student.last_name ?? ''
    this.age = worldMembers?.uuid.student.dob ?? '2022'
    this.grade = worldMembers?.uuid.student.classroom.grade ?? 0
    this.city = worldMembers?.uuid.student.city?.name ?? ''
    this.activeSince = worldMembers?.active_since ?? ''
    this.UUID = worldMembers?.uuid.uuid ?? ''
  }

  getAge() {
    const ageNum = new Date().getFullYear() - new Date(this.age).getFullYear()
    return ageNum.toString()
  }

  getFullName() {
    return this.firstName + ' ' + this.lastName
  }

  getUUID() {
    return this.UUID
  }

  getNameByid(id: any) {
    return id === this.UUID ? this.firstName + ' ' + this.lastName : ''
  }

  ActiveSince() {
    const date = new Date(this.activeSince)
    const difference = new Date().getTime() - date.getTime()
    let weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7))
    if (weeks < 1) {
      weeks = 1
      return weeks.toString() + ' Week'
    } else if (weeks <= 4) {
      return weeks.toString() + ' Weeks'
    } else {
      return weeks.toString() + ' Weeks'
    }
  }
}
