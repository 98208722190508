import {Status} from 'app/types'
import {action, observable} from 'mobx'
import {City} from './city'
import {Classroom} from './classroom'
import {RegistrationDto} from './dtos/registration.dto'
import {Institution} from './institution'
import {Model} from './model'

export class Registration extends Model<RegistrationDto> {
  @observable readonly studentId: number
  @observable private readonly firstName: string
  @observable private readonly lastName: string
  @observable private status: Status
  @observable readonly grade: number
  @observable readonly institution: Institution

  constructor(registration: RegistrationDto) {
    super(registration)
    this.studentId = registration?.student_id
    this.firstName = registration?.student?.first_name
    this.lastName = registration?.student?.last_name
    this.status = registration?.status ?? Status.ALL
    this.grade = registration?.student?.classroom?.grade ?? 0
    this.institution = new Institution(
      registration?.student?.classroom?.institution,
    )
  }

  getName(): string {
    return this.firstName + ' ' + this.lastName
  }

  getStudentCity(): City {
    return new City(this.dto?.student?.city)
  }

  getStudentCityName(): string {
    return this.getStudentCity().getName()
  }

  @action
  setStatus(status?: Status) {
    this.status = status ?? this.status
  }

  getStatus(): Status {
    return this.status
  }
}
