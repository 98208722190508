/**
 *
 * Asynchronously loads the component for Subjects
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Subjects = lazyLoad(
  () => import('./index'),
  (module) => module.Subjects,
)
