import moment from 'moment'
import {Status} from 'app/types'
import {action, observable} from 'mobx'
import {Model} from './model'
import {Timestamp} from './timestamp'

export class Schedule extends Model {
  @observable private readonly experienceId: number
  @observable private readonly worldId: number
  @observable readonly registrationCount: number
  @observable readonly startTime: Timestamp
  @observable readonly endTime: Timestamp
  @observable readonly status: number
  @observable private meetingUrl: string | undefined
  @observable private recordingUrl: string | undefined
  @observable private readonly mentor: {
    first_name: string
    last_name: string
    id: number
  }

  constructor(schedule: any) {
    super(schedule)
    this.experienceId = schedule?.experience_id
    this.worldId = schedule?.world_id
    this.registrationCount = schedule?.registration_count ?? 0
    this.startTime = new Timestamp(schedule?.start_time)
    this.endTime = new Timestamp(schedule?.end_time)
    this.status = schedule?.status ?? 0
    this.mentor = schedule?.experience?.mentor
  }

  @action
  setMeetingUrl(link: string) {
    this.meetingUrl = link
  }

  getMeetingUrl(): string | undefined {
    return this.meetingUrl
  }

  @action
  setRecordingUrl(link: string) {
    this.recordingUrl = link
  }

  getRecordingUrl(): string | undefined {
    return this.recordingUrl
  }

  getExperienceId(): number {
    return this.experienceId
  }

  getWorldId(): number {
    return this.worldId
  }

  getMentorName(): string {
    return `${this.mentor.first_name} ${this.mentor.last_name}`
  }
}
