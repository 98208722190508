import _ from 'lodash'
import {observable} from 'mobx'

export class Model<T = {}> {
  @observable private readonly id: number
  @observable protected readonly dto: T
  @observable private readonly slug: string

  constructor(dto: T) {
    this.id = _.get(dto, 'id')
    this.dto = dto
    this.slug = _.get(dto, 'slug')
  }

  // Server always returns numeric id
  getId(): string {
    return this.id?.toString() ?? ''
  }

  getNumericId(): number {
    return this.id
  }

  getSlug(): string {
    return this.slug ?? ''
  }
}
