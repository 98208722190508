import {observable} from 'mobx'
import {Model} from './model'
import {Timestamp} from './timestamp'

export class Transaction extends Model {
  @observable readonly startTime: Timestamp
  @observable readonly endTime: Timestamp | undefined
  @observable readonly status: number
  @observable readonly registrationCount: number
  @observable readonly totalEarning: number
  @observable readonly earningAfterDeduction: number
  @observable readonly totalAmount: number
  @observable readonly transactionCount: number
  @observable readonly worldTransactionDetails: any[]

  constructor(transaction: any) {
    super(transaction)

    this.startTime = new Timestamp(transaction.start_time)
    this.endTime = new Timestamp(transaction?.end_time)
    this.status = transaction.status ?? 0
    this.registrationCount = transaction.registration_count ?? 0
    this.totalEarning = transaction.total_earning ?? 0
    this.earningAfterDeduction = transaction.earning_after_deduction ?? 0
    this.totalAmount = transaction.totalAmount ?? 0
    this.transactionCount = transaction.transactionCount ?? 0
    this.worldTransactionDetails = transaction.world ?? []
  }
}
