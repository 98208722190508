/**
 *
 * Asynchronously loads the component for Chats
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Chats = lazyLoad(
  () => import('./index'),
  (module) => module.StreamChat,
)
