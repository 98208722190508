import {Model} from './model'

export class Image extends Model {
  private readonly imagePath: string

  constructor(image: any) {
    super(image)
    this.imagePath = image.image_path || ''
  }

  getImagePath(): string {
    return this.imagePath
  }
}
