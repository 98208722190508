/**
 *
 * Asynchronously loads the component for Institutions
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Institutions = lazyLoad(
  () => import('./index'),
  (module) => module.Institutions,
)
