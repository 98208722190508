import _ from 'lodash'
import {action, observable} from 'mobx'
import {Model} from './model'

export class WorldLibrary extends Model {
  @observable readonly libraryId: number
  @observable readonly url: string
  @observable readonly title: string
  @observable readonly description: string
  @observable readonly file_type: string
  @observable readonly created_at: string

  constructor(worldLibrary: any) {
    super(worldLibrary)
    this.libraryId = worldLibrary?.id ?? 0
    this.url = worldLibrary?.url ?? ''
    this.title = worldLibrary?.title ?? ''
    this.description = worldLibrary?.description ?? ''
    this.file_type = worldLibrary?.file_type ?? ''
    this.created_at = worldLibrary?.created_at ?? ''
  }
}
