import {List} from './list'
import {WorldLibrary} from './world-library'

export class WorldsLibraryList extends List<WorldLibrary> {
  constructor(rawData?: any) {
    super(rawData, WorldLibrary)
  }

  getAllWorlds(): WorldsLibraryList {
    const list = new WorldsLibraryList()
    return list
  }
}
