import {observable} from 'mobx'
import _ from 'lodash'

export class Sort {
  @observable readonly sorted: boolean
  @observable readonly unsorted: boolean
  @observable readonly empty: boolean

  constructor(rawData: any) {
    this.sorted = _.get(rawData, 'sorted')
    this.unsorted = _.get(rawData, 'unsorted')
    this.empty = _.get(rawData, 'empty')
  }
}
