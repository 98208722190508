/**
 *
 * Asynchronously loads the component for Questions
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Questions = lazyLoad(
  () => import('./index'),
  (module) => module.Questions,
)
