import _ from 'lodash'
import {List} from './list'
import {Schedule} from './schedule'

export class ScheduleList extends List<Schedule> {
  constructor(rawData?: any) {
    super(rawData, Schedule)
  }

  orderByDate(descending?: boolean): ScheduleList {
    let list = new ScheduleList()
    let sortingFunction = (a, b) =>
      a.startTime.inSeconds() > b.startTime.inSeconds() ? 1 : -1

    if (descending) {
      sortingFunction = (a, b) =>
        a.startTime.inSeconds() < b.startTime.inSeconds() ? 1 : -1
    }
    list.addItems(this.items.sort(sortingFunction))

    return list
  }
}
