/**
 *
 * Asynchronously loads the component for OnlineExp
 *
 */

import {lazyLoad} from 'utils/loadable'

export const OnlineExp = lazyLoad(
  () => import('./index'),
  (module) => module.OnlineExp,
)
