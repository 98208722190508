const data = [
  {
    id: 2,
    first_name: 'DSA',
    last_name: 'Aman',
    dob: null,
    mobile_number: '8697404166',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'admin',
    one_liner: 'Like to teach',
    about_me: 'A new',
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 2,
      },
      {
        id: 4,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 2,
      },
    ],
  },
  {
    id: 8,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '9148499051',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 9,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '8697404168',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 10,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '8697414166',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 11,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '1111111111',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'admin',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 12,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '2222222222',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 13,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '3333333333',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 14,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '3333333334',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 15,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '3333333335',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 16,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '3333333336',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [],
  },
  {
    id: 17,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '3333333337',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 17,
      },
    ],
  },
  {
    id: 18,
    first_name: 'DS',
    last_name: 'Aman',
    dob: null,
    mobile_number: '3333333339',
    avatar_url: 'justRandom',
    cover_img: null,
    user_type: 'teacher',
    one_liner: null,
    about_me: 'A new',
    subjects: [
      {
        id: 2,
        name: 'Computer Science',
        description: 'Computer science is the hottest subject today.',
        laravel_through_key: 18,
      },
      {
        id: 3,
        name: 'History',
        description: 'Learn about the past.',
        laravel_through_key: 18,
      },
    ],
  },
]

export default data
