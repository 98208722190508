const data = [
  {
    id: 8,
    title: 'Test Title',
    description:
      '<p>Warning: [JSS] Function values inside function rules are not supported.<br>warning @ tiny-warning.esm.js:11<br>onUpdate @ jss-plugin-rule-value-function.esm.js:47<br>onUpdate @ jss.esm.js:1284<br>updateOne @ jss.esm.js:964<br>update @ jss.esm.js:93</p>',
    guidelines:
      '<p>Warning: [JSS] Function values inside function rules are not supported.<br>warning @ tiny-warning.esm.js:11<br>onUpdate @ jss-plugin-rule-value-function.esm.js:47<br>onUpdate @ jss.esm.js:1284<br>updateOne @ jss.esm.js:964<br>update @ jss.esm.js:93</p>',
    outcome:
      '<p>Warning: [JSS] Function values inside function rules are not supported.<br>warning @ tiny-warning.esm.js:11<br>onUpdate @ jss-plugin-rule-value-function.esm.js:47<br>onUpdate @ jss.esm.js:1284<br>updateOne @ jss.esm.js:964<br>update @ jss.esm.js:93</p>',
    time_commitment: '2 Hour',
    group_size: 5,
    requirement:
      '<p>Warning: [JSS] Function values inside function rules are not supported.<br>warning @ tiny-warning.esm.js:11<br>onUpdate @ jss-plugin-rule-value-function.esm.js:47<br>onUpdate @ jss.esm.js:1284<br>updateOne @ jss.esm.js:964<br>update @ jss.esm.js:93</p>',
    image:
      'https://sgp1.digitaloceanspaces.com/static-edvolve/media/KkbkQpNd1apQSfhqd6ZROqsgCFwjbQ2epymekTkP.jpg',
    fee: 100,
    status: 0,
    mentor_id: '94c7d945-4d1a-41d2-a863-b195270fe75b',
    primary_subject_id: 1,
    secondary_subject_id: 2,
    language_id: 1,
    grades: [
      {
        id: 1,
        grade: '1',
        laravel_through_key: 8,
      },
      {
        id: 2,
        grade: '2',
        laravel_through_key: 8,
      },
      {
        id: 3,
        grade: '3',
        laravel_through_key: 8,
      },
      {
        id: 4,
        grade: '4',
        laravel_through_key: 8,
      },
      {
        id: 5,
        grade: '5',
        laravel_through_key: 8,
      },
      {
        id: 6,
        grade: '6',
        laravel_through_key: 8,
      },
    ],
    primary_subject: {
      id: 1,
      name: 'Biology',
      description: 'Biology is a branch of science',
    },
    secondary_subject: {
      id: 2,
      name: 'Computer Science',
      description: 'Computer science is the hottest subject today.',
    },
    language: {
      id: 1,
      language: 'English',
    },
    uuid: {
      uuid: '94c7d945-4d1a-41d2-a863-b195270fe75b',
      user_id: 12,
      student_id: null,
      user: {
        id: 12,
        first_name: 'Vikshak J',
        last_name: 'Prakash',
        mobile_number: '2222222222',
        email: 'edisond1@gmail.com',
        avatar_url:
          'https://sgp1.digitaloceanspaces.com/static-edvolve/media/Gh9CH0vwsllskvWwpkMvafq5oPGHIFUrvyqVVTiz.jpg',
      },
    },
  },
]

export default data
