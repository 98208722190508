/**
 *
 * UploadImage
 *
 */
import React, { useRef, memo } from 'react'
import { ImageOutlined } from '@material-ui/icons'
import { useNotifier } from 'app/contexts'

interface Props {
  onSelectImage: (file: File) => void

  disable?: boolean

  text?: string
}

export const UploadImageActivity = memo((props: Props) => {
  const { onSelectImage, disable, text } = props
  const [errorMsg, setErrorMsg] = React.useState('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const notifier = useNotifier()

  const onSelectFile = (e) => {
    if (e.target?.files?.length) {
      if(e.target.files.length > 10) {
        setErrorMsg('profileContainer.imageErrorMsg');
        notifier.error('You can upload maximum 10 images at a time');
        return;
      }
      const files = e.target.files;
      const maxSize = 2000000; // 2MB
      const validFiles = [] as any;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > maxSize) {
          setErrorMsg('profileContainer.imageErrorMsg');
          notifier.error('File size should be less than 2MB');
        } else if (
          !['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
        ) {
          setErrorMsg('profileContainer.imageErrorMsg');
          notifier.error('Only Images are allowed');
        } else {
          validFiles.push(file);
        }
      }

      if (validFiles.length > 0) {
        onSelectImage(validFiles)
      }
    } else {
      setErrorMsg('profileContainer.imageErrorMsg');
      notifier.error('Something went wrong! Please try again');
    }
  };

  return (
    <React.Fragment>
      <div>
        <input
          type='file'
          accept='image/*'
          onChange={onSelectFile}
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
          disabled={disable}
        />
      </div>
      <ImageOutlined onClick={() => fileInputRef.current?.click()} />
    </React.Fragment>
  )
})
