import moment from 'moment'
import {Status} from 'app/types'
import {action, observable} from 'mobx'
import {Model} from './model'
import {Timestamp} from './timestamp'

export class Batch extends Model {
  @observable private readonly courseId: number
  @observable readonly batchId: number
  @observable readonly batchName: string
  @observable readonly className: string
  @observable readonly registrationCount: number
  @observable readonly startTime: Timestamp
  @observable readonly status: number
  @observable private meetingUrl: string | undefined

  constructor(batch: any) {
    super(batch)
    this.courseId = batch?.course_id
    this.batchId = batch?.batch_id
    this.className = batch?.name
    this.batchName = batch?.batch_name
    this.registrationCount = batch?.registration_count
    this.startTime = new Timestamp(batch?.start_time)
    this.status = batch?.status ?? 0
  }

  @action
  setMeetingUrl(link: string) {
    this.meetingUrl = link
  }

  getMeetingUrl(): string | undefined {
    return this.meetingUrl
  }

  getCourseId(): number {
    return this.courseId
  }

  isUpcoming(): boolean {
    return true
    // return (
    //   !this.endTime.exists() ||
    //   moment(this.endTime.getEpoch()).add(1, 'hour').isAfter(moment())
    // )
  }
}
