import {List} from './list'
import {Worlds} from './worlds'
import {Status} from 'app/types'

export class WorldsList extends List<Worlds> {
  constructor(rawData?: any) {
    super(rawData, Worlds)
  }

  getApprovedWorlds(): WorldsList {
    const list = new WorldsList()
    list.addItems(this.filter((item) => item.getStatus() === Status.APPROVED))
    return list
  }

  getAllWorlds(): WorldsList {
    const list = new WorldsList()
    list.addItems(
      this.filter(
        (item) => item.isApproved() || item.isPending() || item.isRejected(),
      ),
    )
    return list
  }
}
