/**
 *
 * Asynchronously loads the component for CreateOnlineExp
 *
 */

import {lazyLoad} from 'utils/loadable'

export const CreateWorlds = lazyLoad(
  () => import('./index'),
  (module) => module.CreateWorlds,
)
