/**
 *
 * Asynchronously loads the component for World
 *
 */

import {lazyLoad} from 'utils/loadable'

export const World = lazyLoad(
  () => import('./index'),
  (module) => module.World,
)
