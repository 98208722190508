/**
 *
 * Webinar
 *
 */
import React, {memo, useEffect} from 'react'
import {Box, Grid, Paper} from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import Countdown from 'react-countdown'
import {Text} from '../Text'
import {Webinar} from '../../models'
import {Button} from '../Button'
import {observer} from 'mobx-react'
import {Status} from 'app/types'

interface WebinarComponentProps {
  webinar: Webinar

  /**
   * When timer reaches (START_TIME - 10 minutes)
   */
  onCountdownComplete: () => void

  onStartWebinar: () => void

  onDelete?: () => void

  onViewQuestions?: () => void

  isPast?: boolean
}

export const WebinarComponent = memo(
  observer(
    ({
      webinar,
      isPast = false,
      onCountdownComplete,
      onStartWebinar,
    }: WebinarComponentProps) => {
      const {
        startTime,
        endTime,
        cities,
        classrooms,
        subjects,
        institutions,
      } = webinar
      const status = webinar.getStatus()
      const showLaunchButton = webinar.isUpcoming() && webinar.getLaunchUrl()
      const showCountDown = status !== Status.REJECTED
      useEffect(() => {
        onCountdownComplete()
      }, [onCountdownComplete, webinar])

      return (
        <Paper>
          <Box padding={2}>
            <Box>
              <Text
                bold
                uppercase
                variant='body2'
                tx='common.title'
                color='secondary'
              />
              <Text
                variant='body2'
                text={webinar.getTitle()}
                color='textSecondary'
              />
            </Box>
            <Box pt={2} hidden={!webinar.getDescription()}>
              <Text
                bold
                uppercase
                variant='body2'
                tx='common.description'
                color='secondary'
              />
              <Text
                variant='body2'
                text={webinar.getDescription()}
                color='textSecondary'
              />
            </Box>
            <Box pt={2}>
              <Text
                bold
                uppercase
                variant='body2'
                tx='webinarsContainer.startsAt'
                color='secondary'
              />
              <Text
                variant='body2'
                text={moment(startTime.getEpoch()).format('LLL')}
                color='textSecondary'
              />
            </Box>
            <Box pt={2}>
              <Text
                bold
                uppercase
                variant='body2'
                tx='webinarsContainer.endsAt'
                color='secondary'
              />
              <Text
                variant='body2'
                text={moment(endTime.getEpoch()).format('LLL')}
                color='textSecondary'
              />
            </Box>
            <Box pt={2}>
              <Text
                bold
                uppercase
                variant='body2'
                tx='common.subjects'
                color='secondary'
              />
              {_.map(subjects.items, (item) => (
                <Text
                  key={item.getId()}
                  variant='body2'
                  text={item.getName()}
                  color='textSecondary'
                />
              ))}
            </Box>
            {institutions.hasItems() && (
              <Box pt={2}>
                <Text
                  bold
                  uppercase
                  variant='body2'
                  tx='common.institutions'
                  color='secondary'
                />
                {_.map(institutions.items, (item) => (
                  <Text
                    key={item.getId()}
                    variant='body2'
                    text={item.getName()}
                    color='textSecondary'
                  />
                ))}
              </Box>
            )}
            {classrooms.hasItems() && (
              <Box pt={2}>
                <Text
                  bold
                  uppercase
                  variant='body2'
                  tx='common.classrooms'
                  color='secondary'
                />
                {_.map(classrooms.items, (item) => (
                  <Text
                    key={item.getId()}
                    variant='body2'
                    text={item.getName()}
                    color='textSecondary'
                  />
                ))}
              </Box>
            )}
            {cities.hasItems() && (
              <Box pt={2}>
                <Text
                  bold
                  uppercase
                  variant='body2'
                  tx='common.cities'
                  color='secondary'
                />
                {_.map(cities.items, (item) => (
                  <Text
                    key={item.getId()}
                    variant='body2'
                    text={item.getName()}
                    color='textSecondary'
                  />
                ))}
              </Box>
            )}
            {!!webinar.getNotes().length && (
              <Box pt={2}>
                <Text
                  bold
                  uppercase
                  variant='body2'
                  tx='webinarsContainer.thingsToNote'
                  color='secondary'
                />
                {_.map(webinar.getNotes(), (item) => (
                  <Text
                    key={item}
                    variant='body2'
                    text={item}
                    color='textSecondary'
                  />
                ))}
              </Box>
            )}
            <Box pt={2}>
              <Text
                bold
                uppercase
                variant='body2'
                tx='webinarsContainer.status'
                color='secondary'
              />
              <Text
                variant='body2'
                tx={
                  status === Status.APPROVED
                    ? 'webinarsContainer.approved'
                    : status === Status.PENDING
                    ? 'webinarsContainer.inReview'
                    : 'webinarsContainer.rejected'
                }
                color='textSecondary'
              />
            </Box>
            <Grid container>
              {/* {isPast && (
                <Grid item xs={6}>
                  <Box display='flex' justifyContent='center'>
                    <Button
                      size='medium'
                      variant='contained'
                      color='primary'
                      onClick={onViewQuestions}
                      text={{tx: 'webinarsContainer.viewQuestions'}}
                      spacingProps={{
                        marginTop: 2,
                      }}
                    />
                  </Box>
                </Grid>
              )} */}
              {showLaunchButton && (
                <Grid item xs={6}>
                  <Box display='flex'>
                    <Button
                      size='medium'
                      variant='contained'
                      color='primary'
                      onClick={onStartWebinar}
                      text={{tx: 'webinarsContainer.startClass', size: 12}}
                      spacingProps={{
                        marginTop: 2,
                      }}
                      disabled={!webinar.getLaunchUrl()}
                    />
                  </Box>
                </Grid>
              )}
              {/* <Grid item xs={showLaunchButton || isPast ? 6 : 12}>
                <Box display='flex' justifyContent='center'>
                  <Button
                    size='medium'
                    variant='contained'
                    color='secondary'
                    onClick={onDelete}
                    text={{tx: 'common.delete'}}
                    spacingProps={{
                      marginTop: 2,
                    }}
                    // disabled={!webinar.getLaunchUrl()}
                  />
                </Box>
              </Grid> */}
            </Grid>
            {showCountDown && (
              <Countdown
                date={moment(webinar.startTime.getEpoch())
                  .subtract(10, 'minutes')
                  .valueOf()}
                intervalDelay={1000}
                precision={1000}
                onComplete={onCountdownComplete}
                renderer={({days, hours, minutes, seconds}) => {
                  const targetDate = moment()
                    .add(days, 'days')
                    .add(hours, 'hours')
                    .add(minutes, 'minutes')
                    .add(seconds, 'seconds')

                  if (targetDate.isAfter(moment())) {
                    return (
                      <Box pt={2} display='flex'>
                        (Starts {targetDate.add(10, 'minutes').fromNow()})
                      </Box>
                    )
                  } else {
                    return <></>
                  }
                }}
              />
            )}
          </Box>
        </Paper>
      )
    },
  ),
)
