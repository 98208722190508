import {List} from './list'
import {Class} from './class'

export class ClassList extends List<Class> {
  constructor(rawData?: any) {
    super(rawData, Class)
  }

  orderByDate(descending?: boolean): ClassList {
    let list = new ClassList()
    let sortingFunction = (a, b) =>
      a.startTime.inSeconds() > b.startTime.inSeconds() ? 1 : -1

    if (descending) {
      sortingFunction = (a, b) =>
        a.startTime.inSeconds() < b.startTime.inSeconds() ? 1 : -1
    }
    list.addItems(this.items.sort(sortingFunction))

    return list
  }
}
