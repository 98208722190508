import {List} from './list'
import {Webinar} from './webinar'

export class WebinarList extends List<Webinar> {
  constructor(rawData?: any) {
    super(rawData, Webinar)
  }

  getPastWebinars(): WebinarList {
    const list = new WebinarList()
    list.addItems(this.filter((item) => item.isPast()))
    return list
  }

  getUpcomingWebinars(): WebinarList {
    const list = new WebinarList()
    list.addItems(this.filter((item) => item.isUpcoming()))
    return list
  }
}
