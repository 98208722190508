/**
 *
 * DropDownInput
 *
 */
import React, {memo} from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import _ from 'lodash'
import {Spacing, SpacingProps} from '../Spacing'
import {Text} from '../Text'
import {observer} from 'mobx-react'

export interface Option {
  label: string
  value: any
}

interface Props extends SelectProps {
  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps

  /**
   * options for dropdown input
   * @deprecated
   */
  options?: {key: string; value: string}[]

  /**
   * Options to be displayed
   */
  optionArr?: Option[]

  /**
   * label of input field
   */
  label?: string

  /**
   * DropdownInput height
   */
  height?: number

  /**
   * DropdownInput width
   */
  width?: number

  /**
   * DropdownInput max height
   */
  maxHeight?: number

  /**
   * DropdownInput max width
   */
  maxWidth?: number

  /**
   * DropdownInput min height
   */
  minHeight?: number

  /**
   * DropdownInput min width
   */
  minWidth?: number

  /**
   * if 'hasError' is true then input field will become red
   */
  hasError?: boolean

  /**
   * I18n key of the error message
   */
  errorMessage?: string
}

const styles = (theme: Theme) => ({
  formControl: {
    height: (props) => props.height,
    maxHeight: (props) => props.maxHeight,
    minHeight: (props) => props.minHeight,
    width: (props) => props.width,
    maxWidth: (props) => props.maxWidth,
    minWidth: (props) => props.minWidth,
  },
})

export const DropDownInput: React.FunctionComponent<Props> = memo(
  withStyles(styles)(
    observer((props: Props & WithStyles<typeof styles>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        spacingProps,
        classes,
        errorMessage,
        hasError,
        options,
        optionArr,
        label,
        children,
        ...rest
      } = props
      let items = optionArr || []

      if (!!options?.length) {
        items = _.map(options, (option) => ({
          label: option.key,
          value: option.value,
        }))
      }

      return (
        <Spacing {...spacingProps}>
          <FormControl
            fullWidth
            className={classes.formControl}
            error={hasError}
          >
            <InputLabel> {label} </InputLabel>
            <Select
              {...rest}
              renderValue={() => <Text text={(rest.value as string) || ''} />}
            >
              {items.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
          </FormControl>
        </Spacing>
      )
    }),
  ),
)
