import _ from 'lodash'
import { observable } from 'mobx'
import { Model } from './model'

export class WorldActivityFeed extends Model {
  @observable readonly actor: string
  @observable readonly actorName: string
  @observable readonly actorImage: string
  @observable readonly foreignId: string
  @observable readonly activityId: string
  @observable readonly feedImage:  string | string[]
  @observable readonly feedText: string
  @observable readonly latestReactions: []
  @observable readonly likes: string[]
  @observable readonly likeCount: number
  @observable readonly comments: []
  @observable readonly hasMoreComments: number
  @observable readonly commentBy: string
  @observable readonly commentText: string
  @observable readonly likeReactionId: string
  @observable readonly commentReactionId: string
  @observable readonly likerId: string
  @observable readonly latestReactionsExtra: []
  @observable readonly object: string
  @observable readonly origin: string
  @observable readonly reactionsCounts: []
  @observable readonly target: string
  @observable readonly time: string
  @observable readonly verb: string
  @observable readonly to: []
  @observable readonly tags: []
  @observable readonly world: []

  constructor(worldActivityFeed: any) {
    super(worldActivityFeed)
    this.actor = worldActivityFeed?.actor ?? ''
    this.actorName = worldActivityFeed?.actor_data?.name ?? ''
    this.actorImage = worldActivityFeed?.actor_data?.avatar_url ?? ''
    this.foreignId = worldActivityFeed?.foreign_id ?? ''
    this.feedImage = worldActivityFeed?.image ?? ''
    this.feedText = worldActivityFeed?.text ?? ''
    this.activityId = worldActivityFeed?.id ?? ''
    this.latestReactions = worldActivityFeed?.latest_reactions ?? []
    this.likes = worldActivityFeed?.latest_reactions.like ?? []
    this.likeCount = worldActivityFeed?.reaction_counts?.like ?? 0
    this.comments = worldActivityFeed?.latest_reactions.comment ?? []
    this.hasMoreComments = worldActivityFeed?.reaction_counts?.comment ?? 0
    this.commentBy =
      worldActivityFeed?.latest_reactions?.comment?.user?.data?.name ?? ''
    this.commentText =
      worldActivityFeed?.latest_reactions?.comment?.data?.text ?? ''
    this.likerId = worldActivityFeed?.latest_reactions?.like?.user_id ?? ''
    this.likeReactionId = worldActivityFeed?.latest_reactions?.like?.id ?? ''
    this.commentReactionId =
      worldActivityFeed?.latest_reactions?.comment?.id ?? ''
    this.latestReactionsExtra = worldActivityFeed?.latest_reactions_extra ?? []
    this.object = worldActivityFeed?.object ?? ''
    this.origin = worldActivityFeed?.object ?? null
    this.reactionsCounts = worldActivityFeed?.reaction_counts ?? []
    this.target = worldActivityFeed?.target ?? ''
    this.time = worldActivityFeed?.time ?? ''
    this.verb = worldActivityFeed?.verb ?? ''
    this.to = worldActivityFeed?.to ?? []
    this.tags = worldActivityFeed?.tag ?? []
    this.world = worldActivityFeed?.world ?? []
  }

  getCommentBy(comment: any) {
    return comment?.user?.data?.name
  }

  getCommentText(comment: any) {
    return comment?.data?.text
  }

  getCommentAvatar(comment: any) {
    return comment?.user?.data?.avatar_url
  }

  getLikeReactionId(userId : string, likes: any) {
    return likes.filter((like: any) => like?.user_id === userId)[0]?.id
  }

  getLikeUserId(likes: any) {
    return likes.filter((like: any) => like?.user_id)
  }

  getTagName(tag: any) {
    return tag?.name
  }

  getLikedUserData(likes: any) {
    return likes.map((like: any) => {
      return {
        id: like?.user_id,
        name: like?.user?.data?.name,
        avatar: like?.user?.data?.avatar_url,
      }
    })
  }

  getIsLikedByUser(userId: string) {
    return this.likes.some((like: any) => like?.user_id === userId)
  }

  getFirstLike(userId: string) {
    return this.likes.filter((like: any) => like?.user_id !== userId)[0]
  }

}
