import {List} from './list'
import {WorldActivityFeed} from './world-activity-feed'

export class WorldActivityFeedList extends List<WorldActivityFeed> {
  constructor(rawData?: any) {
    super(rawData, WorldActivityFeed)
  }

  getAllActivityFeed(): WorldActivityFeedList {
    const list = new WorldActivityFeedList()
    return list
  }
}
