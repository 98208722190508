import {observable} from 'mobx'
import {Transaction} from './transaction'
import {Course} from './course'

export class CourseTransaction extends Transaction {
  @observable readonly courseId: number
  @observable readonly name: string
  @observable readonly additionalInfo: string
  @observable readonly course: Course

  constructor(transactionCourse: any) {
    super(transactionCourse)

    this.courseId = transactionCourse?.course_id
    this.name = transactionCourse?.name ?? ''
    this.additionalInfo = transactionCourse?.additional_info ?? ''
    this.course = new Course(transactionCourse?.course)
  }
}
