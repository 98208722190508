import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import _ from 'lodash'

interface EditorProps {
  /**
   * placeholder for the editor
   */
  placeholder: string

  /**
   * lable for the editor
   */
  label?: string | null

  /**
   * value in html
   */
  data: string

  /**
   * has error or not
   */
  hasError?: Boolean | null

  minMax?: {min: number; max: number}

  /**
   * method to update the value
   */
  onChange: (description: string) => void
}

export const Editor: React.FC<EditorProps> = (props: EditorProps) => {
  const {
    placeholder,
    label = '',
    data,
    hasError = false,
    minMax = {min: 0, max: 100},
    onChange,
  } = props

  return (
    <>
      <div style={{margin: '0.75rem 0', fontSize: '1rem'}}>{label}</div>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        // onReady={(editor) => {
        //   console.log('Editor is ready to use!', editor)
        // }}
        config={{
          height: 200,
          removePlugins: ['MediaEmbed'],
          placeholder: placeholder,
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange(data)
        }}
      />
      <div
        style={{
          fontSize: !hasError ? '0.8rem' : '1rem',
          color: !hasError ? '#555' : 'red',
          transition: 'font-size 0.5s',
        }}
      >
        Min {minMax.min} chars. {data.trim().length}/{minMax.max} Max
      </div>
    </>
  )
}
