const data = [
  {
    id: 87,
    experience_id: 53,
    start_time: 1628227800,
    end_time: 1628231400,
    status: 1,
    registration_count: 0,
    total_earning: 1234,
    earning_after_deduction: 1111,
    experience: {
      id: 53,
      owner_id: 42,
      title: 'The Romanticism movement in Europe',
      mentor: {
        id: 42,
        first_name: 'Arpith',
        last_name: 'C',
        avatar_url:
          'https://api.edvolve.in/media/26dbb4aadd472bea29ea9b12934d3837fileName.jpg',
        about_me:
          "Hi everyone, welcome to my profile! I'm Arpith, and I'm here on a mission to make you fall in love with history and creative pursuits.\n\nI tripled-majored in college, studying literature, psychology and history. I am so glad I chose that route! I believe that having both a strong knowledge of pedagogy and a strong background in content has enabled me to succeed in designing classes that are both interesting and intellectually stimulating.\n\nMy goal is to create lifelong learners.  Everyone should strive for constant improvement and intellectual growth. My focus was primarily on modern Europe, but I studied the modern Middle East as well.  I feel that studying topics of interest leads to growth that benefits us throughout our lives.\n\nMy history experiences always connect modern day issues to the historical themes we are studying. \n\nHere's to a fantastic journey with you all exploring the undiscovered, and helping you find meaning in the pursuit of historical topics :)",
        city_id: 2,
      },
    },
  },
  {
    id: 88,
    experience_id: 53,
    start_time: 1628667000,
    end_time: 1628670600,
    status: 1,
    registration_count: 0,
    total_earning: 1234,
    earning_after_deduction: 1111,
    experience: {
      id: 53,
      owner_id: 42,
      title: 'The Romanticism movement in Europe',
      mentor: {
        id: 42,
        first_name: 'Arpith',
        last_name: 'C',
        avatar_url:
          'https://api.edvolve.in/media/26dbb4aadd472bea29ea9b12934d3837fileName.jpg',
        about_me:
          "Hi everyone, welcome to my profile! I'm Arpith, and I'm here on a mission to make you fall in love with history and creative pursuits.\n\nI tripled-majored in college, studying literature, psychology and history. I am so glad I chose that route! I believe that having both a strong knowledge of pedagogy and a strong background in content has enabled me to succeed in designing classes that are both interesting and intellectually stimulating.\n\nMy goal is to create lifelong learners.  Everyone should strive for constant improvement and intellectual growth. My focus was primarily on modern Europe, but I studied the modern Middle East as well.  I feel that studying topics of interest leads to growth that benefits us throughout our lives.\n\nMy history experiences always connect modern day issues to the historical themes we are studying. \n\nHere's to a fantastic journey with you all exploring the undiscovered, and helping you find meaning in the pursuit of historical topics :)",
        city_id: 2,
      },
    },
  },
]

export default data
