export class Timestamp {
  // In seconds
  private readonly timestamp: number | undefined

  constructor(time?: number) {
    this.timestamp = time
      ? time.toString().length > 10
        ? time / 1000
        : time
      : undefined
  }

  // Returns UNIX timestamp. This format is used by server for communication.
  // Always use this during API calls
  inSeconds(): number {
    return this.timestamp || 0
  }

  // Returns time in milliseconds
  getEpoch(): number {
    return (this.timestamp || 0) * 1000
  }
}
