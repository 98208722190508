import {observable} from 'mobx'
import {Institution} from './institution'
import {Model} from './model'

export class Classroom extends Model {
  @observable private readonly name: string
  @observable readonly institution: Institution

  constructor(classroom: any) {
    super(classroom)
    this.name = classroom?.name ?? ''
    this.institution = new Institution(classroom?.institution)
  }

  getName = (): string => {
    return this.name
  }
}
