import { List } from './list'
import { Course } from './course'
import { Status } from 'app/types'

export class CourseList extends List<Course> {
  constructor(rawData?: any) {
    super(rawData, Course)
  }

  getApprovedCourses(): CourseList {
    const list = new CourseList()
    list.addItems(this.filter((item) => item.isApproved()))
    return list
  }
}
