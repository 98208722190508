export class FormValidationHelper {
  //.!@#$%^&*()_+-=
  static ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9.,!@#$%^&*()_+-="'?:\n ]*$/
  static validateName(value: string) {
    const regex = /^[a-zA-Z ]{1,25}$/
    return regex.test(value.trim())
  }

  static validateNameWithSpace(value: string) {
    const regex = /^[a-zA-Z\s]*$/
    return regex.test(value.trim())
  }

  static validateLength(value: string, min: number, max: number) {
    return (
      this.ALPHA_NUMERIC_REGEX.test(value.trim()) &&
      value.length >= min &&
      value.length <= max
    )
  }

  static validateStringLength(value: string, min: number, max: number) {
    return value.length >= min && value.length <= max
  }

  static validateNumber(value: number, min: number, max: number) {
    return value >= min && value <= max
  }

  static validateEmail(email: string) {
    const emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailRegx.test(String(email).toLowerCase())
  }

  static validateLink(link: string) {
    const linkRegx = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi

    return linkRegx.test(String(link).toLowerCase())
  }
}
