const data = [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
    name: '3 A',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa2',
    name: '3 A',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa3',
    name: '3 A',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa4',
    name: '3 A',
  },
]

export default data
