/**
 *
 * Modal
 *
 */
import React, { memo } from 'react'
import {
  Box,
  ButtonProps,
  Fade,
  Modal as MUIModal,
  ModalProps,
  Theme,
  WithStyles,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import { Text, TextProps } from '../Text'
import CloseIcon from '@material-ui/icons/Close'
import { Palette } from '@material-ui/core/styles/createPalette'
import { Button } from '../Button'
import { Spacing, SpacingProps } from '../Spacing'

interface Props extends ModalProps {
  /**
   * if open is true, Modal will appear
   */
  open: boolean

  /**
   * Title properties of Modal
   */
  titleText?: TextProps

  /**
   * Modal max width
   */
  maxwidth?: number

  /**
   * background color of Modal
   */
  bgColor?: Palette

  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps

  /**
    * Sbow button
    */
  buttonsDisabled?: string

  /**
   * closing function for Modal
   */
  onDismiss?: () => void

  /**
   * function to submit the form
   * @param e is event
   */
  onSubmit?: (e) => void

  /**
   * function to secondary button
   * @param e is event
   */
  onCancel?: () => void

  /**
   * action button prop
   */
  actionButtonText?: TextProps

  /**
   * cancel button prop
   */
  cancelButtonText?: TextProps

  /**
   * Action button disabled
   */
  submitDisabled?: boolean

  /**
   * Optioncal cover photo
   */
  image?: string

  displayText?: string
}

const styles = (theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10vh 10px',
    overflow: 'auto',
  },
  coverPhoto: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  paper: (props) => ({
    outline: 'none',
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
    maxWidth: !props.maxwidth ? 500 : props.maxwidth,
    backgroundColor: props.bgColor
      ? theme.palette[props.bgColor] || props.bgColor
      : theme.palette.background.paper,
  }),
  box: (props) => ({
    width: !props.image ? '100%' : '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }),
  closeButton: {
    cursor: 'pointer',
  },
})

export const Modal: React.FunctionComponent<Props & ButtonProps> = memo(
  withStyles(styles)(
    (props: Props & ButtonProps & WithStyles<typeof styles>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        classes,
        open,
        children,
        onSubmit,
        spacingProps,
        titleText,
        onDismiss,
        onCancel,
        actionButtonText,
        cancelButtonText,
        submitDisabled,
        image,
        displayText,
        ...rest
      } = props

      return (
        <MUIModal open={open} className={classes.modal} {...rest}>
          <Fade in={open}>
            <form className={classes.paper}>
              <Box display='flex'>
                {image && (
                  <img
                    src={image}
                    className={classes.coverPhoto}
                    style={{ objectFit: 'cover' }}
                  />
                )}
                <Box className={classes.box}>
                  <Spacing {...spacingProps}>
                    <Box display='flex' justifyContent='space-between'>
                      <Text {...titleText} />
                      <CloseIcon
                        onClick={onDismiss}
                        className={classes.closeButton}
                      />
                    </Box>
                    {children}
                    {
                      props.buttonsDisabled === "Yes" ? (
                        <></>
                      ) : (
                        <Box display='flex' justifyContent='center'>
                          <Button
                            size='medium'
                            variant='contained'
                            color='secondary'
                            onClick={onSubmit}
                            spacingProps={{
                              marginTop: 2,
                              marginRight: 2,
                            }}
                            disabled={submitDisabled}
                          >
                            {
                              props.submitDisabled ? (
                                <CircularProgress size={20} />
                              ) : (
                                displayText ? displayText : "Submit"
                              )
                            }
                          </Button>
                          {!!cancelButtonText && (
                            <Button
                              size='medium'
                              variant='contained'
                              color='primary'
                              onClick={onCancel ?? onDismiss}
                              text={
                                !!cancelButtonText
                                  ? cancelButtonText
                                  : { tx: 'common.cancel' }
                              }
                              spacingProps={{
                                marginTop: 2,
                                marginLeft: 2,
                              }}
                            />
                          )}
                        </Box>
                      )
                    }
                  </Spacing>
                </Box>
              </Box>
            </form>
          </Fade>
        </MUIModal>
      )
    },
  ),
)
