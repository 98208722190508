export const classesFixture = [
  {
    id: 89,
    name: 'Class 1',
    description: 'ok',
    course_id: 17,
    batch_id: 35,
    batch_name: 'July batch',
    course_title: 'Learn how Data Science works!',
    start_time: 1625272200,
    status: 1,
    registration_count: 0,
  },
  {
    id: 90,
    name: 'Class 2',
    description: 'ok',
    course_id: 17,
    batch_id: 35,
    batch_name: 'July batch',
    course_title: 'Learn how Data Science works!',
    start_time: 1625274000,
    status: 1,
    registration_count: 0,
  },
  {
    id: 91,
    name: 'Class 3',
    description: 'ok',
    course_id: 17,
    batch_id: 35,
    batch_name: 'July batch',
    course_title: 'Learn how Data Science works!',
    start_time: 1625275800,
    status: 1,
    registration_count: 0,
  },
]
