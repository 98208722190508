import {Model} from './model'
import {observable} from 'mobx'

export class Language extends Model {
  @observable private readonly name: string

  constructor(language: any) {
    super(language)
    this.name = language?.language ?? ''
  }

  getName(): string {
    return this.name
  }
}
