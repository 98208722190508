/**
 *
 * Grid
 *
 */
import React, {memo} from 'react'
import {
  Grid as MUIGrid,
  GridProps,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {Spacing, SpacingProps} from '../Spacing'
import {Palette} from '@material-ui/core/styles/createPalette'

interface Props extends GridProps {
  /**
   * Child element if any
   */
  children?: React.ReactNode
  /**
   * Border radius
   * Note: Border radius is not directly applied on grid. It is applied on the
   * wrapping component 'Spacing' so that curved edges are visible.
   */
  borderRadius?: number

  /**
   * Background color for your grid component
   */
  bgColor?: Palette

  /**
   * Grid height
   */
  height?: number

  /**
   * Grid width
   */
  width?: number

  /**
   * Grid max height
   */
  maxHeight?: number

  /**
   * Grid max width
   */
  maxWidth?: number

  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps
}

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  grid: {
    height: (props) => props.height,
    width: (props) => props.width,
  },
})

/**
 * @deprecated Please use material UI Grid directly
 */
export const Grid: React.FunctionComponent<Props> = memo(
  withStyles(styles)((props: Props & WithStyles<typeof styles>) => {
    const {
      children,
      bgColor,
      borderRadius,
      classes,
      spacingProps,
      ...rest
    } = props

    return (
      <Spacing
        className={classes.root}
        {...spacingProps}
        bgColor={bgColor}
        borderRadius={borderRadius}
      >
        <MUIGrid className={classes.grid} {...rest}>
          {children}
        </MUIGrid>
      </Spacing>
    )
  }),
)
