import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
} from 'notistack'

interface MessageOptions {
  /**
   * Skip localization of string and prints message as it is
   */
  skipTranslation?: boolean
}

export class NotificationsHelper {
  translate: (tx: string) => any
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject,
  ) => SnackbarKey

  constructor(enqueueSnackbar: any, translate: any) {
    this.enqueueSnackbar = enqueueSnackbar
    this.translate = translate
  }

  private getOptions(variant: VariantType): OptionsObject {
    return {
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    }
  }

  error(message: string, options?: MessageOptions) {
    const toast = !options?.skipTranslation ? this.translate(message) : message
    this.enqueueSnackbar(toast, this.getOptions('error'))
  }

  success(message: string, options?: MessageOptions) {
    const toast = !options?.skipTranslation ? this.translate(message) : message
    this.enqueueSnackbar(toast, this.getOptions('success'))
  }

  info(message: string, options?: MessageOptions) {
    const toast = !options?.skipTranslation ? this.translate(message) : message
    this.enqueueSnackbar(toast, this.getOptions('info'))
  }
}
