/**
 *
 * DateInput
 *
 */
import React, {memo} from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DatePickerProps,
} from '@material-ui/pickers'
import {Spacing, SpacingProps} from '../Spacing'
import {Text} from '../Text'
import {Theme, WithStyles, withStyles} from '@material-ui/core'

interface Props extends DatePickerProps {
  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps

  /**
   * DateInput height
   */
  height?: number

  /**
   * DateInput width
   */
  width?: number

  /**
   * DateInput max height
   */
  maxHeight?: number

  /**
   * DateInput max width
   */
  maxWidth?: number

  /**
   * DateInput min height
   */
  minHeight?: number

  /**
   * DateInput min width
   */
  minWidth?: number

  /**
   * I18n key of the error message
   */
  errorMessage?: string
}

const styles = (theme: Theme) => ({
  input: (props) => ({
    height: props.height,
    maxHeight: props.maxHeight,
    minHeight: props.minHeight,
    width: !props.width ? '100%' : props.width,
    maxWidth: props.maxWidth,
    minWidth: props.minWidth,
  }),
})

export const DateInput: React.FunctionComponent<Props> = memo(
  withStyles(styles)((props: Props & WithStyles<typeof styles>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {spacingProps, errorMessage, classes, ...rest} = props

    return (
      <Spacing {...spacingProps}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            className={classes.input}
            error={!!errorMessage}
            {...rest}
          />
          {!!errorMessage && (
            <Text variant='body2' tx={errorMessage} color='error' />
          )}
        </MuiPickersUtilsProvider>
      </Spacing>
    )
  }),
)
