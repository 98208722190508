/**
 *
 * Text
 *
 */
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Theme,
  Typography,
  TypographyProps,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {Spacing, SpacingProps} from '../Spacing'
import {useColors} from 'app'
import _ from 'lodash'

export interface TextProps extends TypographyProps {
  /**
   * Custom text colors specified in app/index as colors
   */
  textColor?: string

  /**
   * Sets the font weight to bold
   */
  bold?: boolean

  /**
   * Aligns the text to center
   */
  center?: boolean

  /**
   * Text align uppercase
   */
  uppercase?: boolean

  /**
   * Key of the localised string to display
   */
  tx?: string

  /**
   * Options for localising a text
   */
  txOptions?: object

  /**
   * A string to display
   */
  text?: string

  /**
   * Font size
   */
  size?: number

  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps

  /**
   * Hides text input
   */
  hidden?: boolean
}

const styles = (theme: Theme) => ({
  text: {
    fontWeight: (props) =>
      props.bold ? theme.typography.fontWeightBold : undefined,
    textAlign: (props) => (props.center ? 'center' : undefined),
    textTransform: (props) => (props.uppercase ? 'uppercase' : undefined),
  },
})

export const Text: React.FunctionComponent<TextProps> = memo(
  withStyles(styles)((props: TextProps & WithStyles<typeof styles>) => {
    const {
      bold,
      children,
      center,
      classes,
      hidden,
      size,
      spacingProps,
      tx,
      text,
      textColor,
      txOptions,
      ...rest
    } = props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {t, i18n} = useTranslation()
    const content = tx ? t(tx, txOptions) : text
    const colors = useColors()

    if (hidden) {
      return <></>
    }

    return (
      <Spacing {...spacingProps}>
        <Typography
          className={classes.text}
          style={{
            fontSize: `${size}px`,
            color: textColor ? colors[textColor]?.main : undefined,
          }}
          {...rest}
        >
          {content || children}
        </Typography>
      </Spacing>
    )
  }),
)
