const data = [
  {
    id: 1,
    name: 'Mumbai',
    created_at: '2020-11-19 12:54:29',
    updated_at: '2020-11-19 12:54:29',
    country: 'India',
  },
  {
    id: 2,
    name: 'Bengaluru',
    created_at: '2020-11-19 12:54:35',
    updated_at: '2020-11-19 12:54:35',
    country: 'India',
  },
]

export default data
