import _ from 'lodash'
import { action, observable } from 'mobx'
import { Model } from './model'
import { RecordingStatus, Status, Target, Team } from 'app/types'
import { Subject, Language, GradeList } from './'
import { Mentor } from './mentor'

export class Course extends Model {
  @observable private readonly title: string
  @observable private readonly description: string
  @observable readonly outcome: string
  @observable readonly featuredImage: string
  @observable readonly fee: number
  @observable readonly groupFee: number
  @observable readonly duration: string
  @observable readonly team: Team
  @observable readonly groupSize: number
  @observable readonly skillLevel: string
  @observable readonly ownerId: number
  @observable readonly primarySubject: Subject
  @observable readonly secondarySubject: Subject
  @observable readonly language: Language
  @observable readonly grades: GradeList
  @observable readonly thingsToNote: { id: number; notes: string }[]
  @observable readonly imgs: { image_path: string }[]
  @observable readonly classes: { id: number, name: string; description: string }[]
  @observable private status: Status
  @observable readonly world: { id: number; title: string }[]

  constructor(course: any) {
    super(course)

    this.title = course?.title ?? ''
    this.description = course?.description ?? ''
    this.outcome = course?.outcome ?? ''
    this.featuredImage = course?.featured_image ?? ''
    this.fee = course?.fee ?? 99
    this.groupFee = course?.group_fee ?? 99
    this.duration = course?.duration ?? ''
    this.team = course?.team ?? Team.FALSE
    this.groupSize = course?.group_size ?? 5
    this.skillLevel = course?.skill_level ?? ''
    this.primarySubject = new Subject(course?.primary_subject)
    this.secondarySubject = new Subject(course?.secondary_subject)
    this.language = new Language(course?.language)
    this.grades = new GradeList(course?.grades)
    this.thingsToNote = course?.notes || []
    this.imgs = course?.imgs || []
    this.classes = course?.classes || []
    this.status = course?.status ?? Status.ALL
    this.ownerId = course?.owner_id ?? 0
    this.world = course?.world ?? []
  }

  getTitle(): string {
    return this.title
  }

  getDescription(): string {
    return this.description
  }

  getNotes(): string[] {
    return _.map(this.thingsToNote, (item) => item.notes)
  }

  @action
  setStatus(status?: Status) {
    this.status = status ?? this.status
  }

  getStatus(): Status {
    return this.status
  }

  isApproved(): boolean {
    return this.status === Status.APPROVED
  }

  isRejected(): boolean {
    return this.status === Status.REJECTED
  }

  isPending(): boolean {
    return this.status === Status.PENDING
  }

  isWorldCourse(worldId: number): any {
    return _.some(this.world, (item) => item.id === worldId)
  }
}
