import _ from 'lodash'
import {List} from './list'
import {Batch} from './batch'

export class BatchList extends List<Batch> {
  constructor(rawData?: any) {
    super(rawData, Batch)
  }

  /**
   * start time might be need to check
   * @returns
   */
  getUpcoming(): BatchList {
    const list = new BatchList()
    const batches = _.filter<Batch>(this.items, (batch: Batch) =>
      batch.isUpcoming(),
    )
    list.addItems(batches)

    return list
  }

  /**
   *
   * All batches 1 hour from endtime are considered as past if it's before current time
   * @returns
   */
  getPast(): BatchList {
    const list = new BatchList()
    const batches = _.filter<Batch>(
      this.items,
      (batch: Batch) => !batch.isUpcoming(),
    )
    list.addItems(batches)

    return list
  }
}
