import {observable} from 'mobx'
import {Model} from './model'
import {Timestamp} from './timestamp'

export class Question extends Model {
  // @observable private readonly id: number
  @observable readonly sessionId: number
  @observable readonly answer: string | null
  @observable readonly question: string | null
  @observable readonly createdAt: Timestamp
  @observable readonly updatedAt: Timestamp

  constructor(question: any) {
    super(question)
    // this.id = question?.id
    this.sessionId = question?.session_id
    this.answer = question?.answer
    this.question = question?.question
    this.createdAt = new Timestamp(question?.created_at)
    this.updatedAt = new Timestamp(question?.updated_at)
  }
}
