import {List} from './list'
import {UserFeed} from './user-feed'

export class UserFeedList extends List<UserFeed> {
  constructor(rawData?: any) {
    super(rawData, UserFeed)
  }

  getAllUserFeed(): UserFeedList {
    const list = new UserFeedList()
    return list
  }
}
