/**
 *
 * Asynchronously loads the component for AdminOnlineExp
 *
 */

import {lazyLoad} from 'utils/loadable'

export const AdminOnlineExp = lazyLoad(
  () => import('./index'),
  (module) => module.AdminOnlineExp,
)
