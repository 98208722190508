const data = [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
    name: 'History',
    teacher: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      first_name: 'Alok',
      last_name: 'Phathak',
      mobile_number: '7452XX25XX',
      avatar_url: '',
      user_type: 'ADMIN',
    },
    details:
      'History is the study of life in society in the past, in all its aspect, in relation to present developments and future hopes',
    url_course: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa2',
    name: 'History',
    teacher: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      first_name: 'Alok',
      last_name: 'Phathak',
      mobile_number: '7452XX25XX',
      avatar_url: '',
      user_type: 'ADMIN',
    },
    details:
      'History is the study of life in society in the past, in all its aspect, in relation to present developments and future hopes',
    url_course: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa3',
    name: 'History',
    teacher: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      first_name: 'Alok',
      last_name: 'Phathak',
      mobile_number: '7452XX25XX',
      avatar_url: '',
      user_type: 'ADMIN',
    },
    details:
      'History is the study of life in society in the past, in all its aspect, in relation to present developments and future hopes',
    url_course: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa4',
    name: 'History',
    teacher: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      first_name: 'Alok',
      last_name: 'Phathak',
      mobile_number: '7452XX25XX',
      avatar_url: '',
      user_type: 'ADMIN',
    },
    details:
      'History is the study of life in society in the past, in all its aspect, in relation to present developments and future hopes',
    url_course: '',
  },
]

export default data
