/**
 *
 * Asynchronously loads the component for Classrooms
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Classrooms = lazyLoad(
  () => import('./index'),
  (module) => module.Classrooms,
)
