import {Model} from './model'
import {action, observable} from 'mobx'
import {Timestamp} from './timestamp'
import {Status} from 'app/types'

export class Class extends Model {
  @observable readonly name: string
  @observable private readonly description: string
  @observable readonly batchName: string
  @observable readonly courseTitle: string
  @observable readonly registrationCount: number
  @observable readonly batchId: number
  @observable readonly courseId: number
  @observable readonly startTime: Timestamp
  @observable readonly endTime: Timestamp
  @observable readonly status: Status
  @observable private meetingUrl: string | undefined
  @observable private launchUrl: string | undefined

  constructor(rawData: any) {
    super(rawData)

    this.name = rawData?.name ?? ''
    this.description = rawData?.description ?? ''
    this.batchName = rawData?.batch_name ?? ''
    this.courseTitle = rawData?.course_title ?? ''
    this.registrationCount = rawData?.registration_count ?? 0
    this.batchId = rawData?.batch_id
    this.courseId = rawData?.course_id
    this.startTime = new Timestamp(rawData?.start_time)
    this.endTime = new Timestamp(rawData?.end_time)
    this.status = rawData?.status
  }

  @action
  setMeetingUrl(link?: string) {
    this.meetingUrl = link
  }

  getMeetingUrl() {
    return this.meetingUrl
  }

  @action
  setLaunchgUrl(link?: string) {
    this.launchUrl = link
  }

  getLaunchUrl() {
    return this.launchUrl
  }

  getName(): string {
    return this.name
  }

  isApproved(): boolean {
    return this.status === Status.APPROVED
  }
}
