/**
 *
 * SharedFile
 *
 */
import React, {memo} from 'react'
import {Box, Paper} from '@material-ui/core'
import {Text} from '../Text'
import GetAppIcon from '@material-ui/icons/GetApp'
import {Button} from '../Button'

interface Props {
  // file name
  name: string

  // file description
  description: string

  // for downloading file
  onClick: () => void
}

export const SharedFile = memo((props: Props) => {
  const {name, description, onClick} = props

  return (
    <Paper>
      <Box padding={2}>
        <Text
          bold
          uppercase
          variant='body2'
          tx='sharedFilesContainer.name'
          color='secondary'
        />
        <Text variant='body2' text={name} color='textSecondary' />
        <Box pt={2}>
          <Text
            bold
            uppercase
            variant='body2'
            tx='sharedFilesContainer.description'
            color='secondary'
          />
          <Text variant='body2' text={description} color='textSecondary' />
        </Box>
        <Box pt={2} display='flex' justifyContent='flex-end'>
          <Button
            onClick={onClick}
            variant='contained'
            text={{tx: 'sharedFilesContainer.download'}}
            startIcon={<GetAppIcon />}
          />
        </Box>
      </Box>
    </Paper>
  )
})
