import _ from 'lodash'
import {action, observable} from 'mobx'
import {Model} from './model'
import {CityList} from './city-list'
import {ClassroomList} from './classroom-list'
import {InstitutionList} from './institution-list'
import {SubjectList} from './subject-list'
import {Timestamp} from './timestamp'
import {RecordingStatus, RoomType, Status, Target} from 'app/types'
import {GradeList} from './grade-list'

export class Webinar extends Model {
  @observable private readonly title: string
  @observable private readonly description: string
  @observable readonly startTime: Timestamp
  @observable readonly webinarId: number
  @observable readonly startTimeTs: any
  @observable readonly endTime: Timestamp
  @observable readonly endTimeTs: any
  @observable readonly subjects: SubjectList
  @observable readonly classrooms: ClassroomList
  @observable readonly institutions: InstitutionList
  @observable readonly cities: CityList
  @observable readonly grades: GradeList
  @observable readonly ownerId: number
  @observable private status: Status
  @observable private recordingStatus: RecordingStatus
  @observable private readonly thingsToNote: {id: number; notes: string}[]
  // Braincert webinar launch URL
  @observable private launchUrl: string | undefined
  @observable private meetingUrl: string | undefined
  @observable readonly featuredImage: string | undefined
  @observable readonly roomType: RoomType
  @observable readonly world: number | undefined
  @observable readonly worldId: string | undefined
  @observable readonly activeInactiveStatus: 1 | 0 

  constructor(webinar: any) {
    super(webinar)

    this.title = webinar?.title ?? ''
    this.description = webinar?.description ?? ''
    this.startTime = new Timestamp(webinar?.start_time)
    this.startTimeTs = webinar?.start_time_ts ?? ''
    this.endTime = new Timestamp(webinar?.end_time)
    this.endTimeTs = webinar?.end_time_ts ?? ''
    this.subjects = new SubjectList(webinar?.subjects)
    this.classrooms = new ClassroomList(webinar?.classrooms)
    this.institutions = new InstitutionList(webinar?.institutions)
    this.cities = new CityList(webinar?.cities)
    this.grades = new GradeList(webinar?.grade_ids)
    this.status = webinar?.status ?? Status.ALL
    this.ownerId = webinar?.owner_id ?? 0
    this.recordingStatus = webinar?.recording_status ?? RecordingStatus.ALL
    this.thingsToNote = webinar?.notes || []
    this.roomType = webinar?.room_type || ''
    this.featuredImage = webinar.featured_image || ''
    this.world = webinar.world || 0
    this.worldId = webinar.worldId || undefined
    this.webinarId = webinar?.id ?? 0
    this.activeInactiveStatus = webinar?.status ?? 1
  }

  getTitle(): string {
    return this.title
  }

  getDescription(): string {
    return this.description
  }

  getNotes(): string[] {
    return _.map(this.thingsToNote, (item) => item.notes)
  }

  isUpcoming(): boolean {
    return this.startTime.getEpoch() > new Date().valueOf()
  }

  isPast(): boolean {
    return this.startTime.getEpoch() < new Date().valueOf()
  }

  @action
  setLaunchUrl(link: string) {
    this.launchUrl = link
  }

  @action
  setMeetingUrl(link: string) {
    this.meetingUrl = link
  }

  @action
  setStatus(status?: Status, recordingStatus?: RecordingStatus) {
    this.status = status ?? this.status
    this.recordingStatus = recordingStatus ?? this.recordingStatus
  }

  getStatus() : Status {
    return this.status
  }

  getRecordingStatus(): RecordingStatus {
    return this.recordingStatus
  }

  getMeetingUrl(): string | undefined {
    return this.meetingUrl
  }

  getLaunchUrl(): string | undefined {
    return this.launchUrl
  }

  getTarget(): Target {
    return this.cities.hasItems() ? Target.GLOBAL : Target.INSTITUTION
  }

  getRoomType(): RoomType {
    return this.roomType
  }

  getaAtiveInactiveStatus (): 1 | 0 {
    return this.activeInactiveStatus
  }
}
