import {observable} from 'mobx'
import {Model} from './model'
import {City} from './city'

// School/Institution
export class Institution extends Model {
  @observable private readonly name: string
  @observable private readonly address: string
  @observable private readonly city: City
  // @observable private readonly createdAt: number
  // @observable readonly updatedAt: number

  constructor(institution: any) {
    super(institution)
    this.name = institution?.name ?? ''
    this.address = institution?.address ?? ''
    this.city = new City(institution?.city)
    // const creationDate = _.get(school, 'created_at')
    // this.createdAt = creationDate
    //   ? new Date(creationDate).valueOf()
    //   : new Date().valueOf()
    // const updationDate = _.get(school, 'updated_at')
    // this.updatedAt = updationDate
    //   ? new Date(updationDate).valueOf()
    //   : new Date().valueOf()
  }

  getName() {
    return this.name
  }

  getAddress() {
    return this.address
  }

  getCityName() {
    return this.city.getName()
  }

  getCountry() {
    return this.city.getCountry()
  }

  getCityId() {
    return this.city.getNumericId()
  }

  // getCreationDate() {
  //   return this.createdAt
  // }
}
