export enum Status {
  ALL = -1,
  APPROVED = 1,
  PENDING = 2,
  REJECTED = 3,
}

export enum RecordingStatus {
  ALL = -1,
  UNPUBLISHED = 0,
  PUBLISHED = 1,
}

export enum Type {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

export enum UrlType {
  START = 'url',
  WATCH = 'recording',
}

export enum Target {
  // Restricted to school level
  INSTITUTION = 'institution',
  // At a city level
  GLOBAL = 'global',
}

export enum RoomType {
  CHAT_OR_DISCUSSION = 'Chat/Discussion',
  EVENT_OR_SHOW = 'Event/Show',
  CONTEST = 'Contest',
  CLASS = 'Class',
}
