/**
 *
 * Spacing
 *
 */
import React, {memo} from 'react'
import styled from 'styled-components/macro'
import {Theme, WithStyles, withStyles} from '@material-ui/core'
import {Palette} from '@material-ui/core/styles/createPalette'

export interface SpacingProps {
  /**
   * Child element if any
   */
  children?: React.ReactNode

  /**
   * Border radius
   */
  borderRadius?: number

  /**
   * Background color for your grid component
   */
  bgColor?: Palette

  /**
   * Additional classes
   */
  className?: string

  /**
   * Padding applied on all the sides
   */
  padding?: number

  /**
   * Space count that will be multiplied with base unit 8px defined in the material UI theme
   */
  paddingVertical?: number

  /**
   * Horizontal padding
   */
  paddingHorizontal?: number

  /**
   * Top padding
   */
  paddingTop?: number

  /**
   * Right padding
   */
  paddingRight?: number

  /**
   * Left padding
   */
  paddingLeft?: number

  /**
   * Bottom padding
   */
  paddingBottom?: number

  /**
   * Margin applied on all the sides
   */
  margin?: number

  /**
   * Space count that will be multiplied with base unit 8px defined in the material UI theme
   */
  marginVertical?: number

  /**
   * Horizontal margin
   */
  marginHorizontal?: number

  /**
   * Top margin
   */
  marginTop?: number

  /**
   * Right margin
   */
  marginRight?: number

  /**
   * Left margin
   */
  marginLeft?: number

  /**
   * Bottom margin
   */
  marginBottom?: number
}

const styles = (theme: Theme) => ({
  root: (props) => ({
    padding: theme.spacing(
      props.paddingTop || props.paddingVertical || props.padding || 0,
      props.paddingRight || props.paddingHorizontal || props.padding || 0,
      props.paddingBottom || props.paddingVertical || props.padding || 0,
      props.paddingLeft || props.paddingHorizontal || props.padding || 0,
    ),
    margin: theme.spacing(
      props.marginTop || props.marginVertical || props.margin || 0,
      props.marginRight || props.marginHorizontal || props.margin || 0,
      props.marginBottom || props.marginVertical || props.margin || 0,
      props.marginLeft || props.marginHorizontal || props.margin || 0,
    ),
    backgroundColor: props.bgColor
      ? theme.palette[props.bgColor] || props.bgColor
      : theme.palette.background,
    borderRadius: (props) => props.borderRadius || 0,
  }),
})

export const Spacing: React.FunctionComponent<SpacingProps> = memo(
  withStyles(styles)((props: SpacingProps & WithStyles<typeof styles>) => {
    const {children, classes, className} = props

    return <Div className={`${classes.root} ${className}`}>{children}</Div>
  }),
)

const Div = styled.div``
