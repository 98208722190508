/**
 *
 * Classroom
 *
 */
import React, {memo} from 'react'
import {Box, Paper} from '@material-ui/core'
import {Text} from '../Text'
import {Classroom} from '../../models'

export const ClassroomComponent = memo(
  ({classroom}: {classroom: Classroom}) => {
    return (
      <Paper>
        <Box padding={2}>
          <Text
            bold
            uppercase
            variant='body2'
            tx='common.institution'
            color='secondary'
          />
          <Text
            variant='body2'
            text={classroom.institution.getName()}
            color='textSecondary'
          />
          <Box pt={2}>
            <Text
              bold
              uppercase
              variant='body2'
              tx='common.class'
              color='secondary'
            />
            <Text
              variant='body2'
              text={classroom.getName()}
              color='textSecondary'
            />
          </Box>
        </Box>
      </Paper>
    )
  },
)
