/**
 *
 * Asynchronously loads the component for SharedFiles
 *
 */

import {lazyLoad} from 'utils/loadable'

export const SharedFiles = lazyLoad(
  () => import('./index'),
  (module) => module.SharedFiles,
)
