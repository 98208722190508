import _ from 'lodash'
import {Sort} from './sort'
import {observable} from 'mobx'

export class Pageable {
  @observable readonly pageSize: number
  @observable readonly pageNumber: number
  @observable readonly unpaged: boolean
  @observable readonly paged: boolean
  @observable readonly offset: number
  @observable readonly sort: Sort

  constructor(rawData: any) {
    this.pageSize = _.get(rawData, 'pageSize')
    this.pageNumber = _.get(rawData, 'pageNumber')
    this.unpaged = _.get(rawData, 'unpaged')
    this.paged = _.get(rawData, 'paged')
    this.offset = _.get(rawData, 'offset')
    this.sort = new Sort(_.get(rawData, 'sort'))
  }
}
