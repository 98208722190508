import _ from 'lodash'
import {action, observable} from 'mobx'
import {Model} from './model'
import {RecordingStatus, Status, Target, Team} from 'app/types'
import {Subject, Language, GradeList} from './'
import {ImageList} from './image-list'
import {Image} from './image'
import {Mentor} from './mentor'

export class Experience extends Model {
  @observable private readonly title: string
  @observable private readonly description: string
  @observable readonly outcome: string
  @observable readonly featuredImage: string
  @observable readonly fee: number
  @observable readonly groupFee: number
  @observable readonly duration: string
  @observable readonly team: Team
  @observable readonly groupSize: number
  @observable readonly skillLevel: string
  @observable readonly ownerId: number
  @observable readonly primarySubject: Subject
  @observable readonly secondarySubject: Subject
  @observable readonly language: Language
  @observable readonly grades: GradeList
  @observable readonly thingsToNote: {id: number; notes: string}[]
  @observable readonly imgs: ImageList
  @observable private status: Status

  constructor(experience: any) {
    super(experience)

    this.title = experience?.title ?? ''
    this.description = experience?.description ?? ''
    this.outcome = experience?.outcome ?? ''
    this.featuredImage = experience?.featured_image ?? ''
    this.fee = experience?.fee ?? 99
    this.groupFee = experience?.group_fee ?? 99
    this.duration = experience?.duration ?? ''
    this.team = experience?.team ?? Team.FALSE
    this.groupSize = experience?.group_size ?? 5
    this.skillLevel = experience?.skill_level ?? ''
    this.primarySubject = new Subject(experience?.primary_subject)
    this.secondarySubject = new Subject(experience?.secondary_subject)
    this.language = new Language(experience?.language)
    this.grades = new GradeList(experience?.grades)
    this.thingsToNote = experience?.notes || []
    this.imgs = new ImageList(experience?.imgs)
    this.status = experience?.status ?? Status.ALL
    this.ownerId = experience?.owner_id ?? 0
  }

  getTitle(): string {
    return this.title
  }

  getDescription(): string {
    return this.description
  }

  getNotes(): string[] {
    return _.map(this.thingsToNote, (item) => item.notes)
  }

  getImages(): string[] {
    return _.map(this.imgs.items, (item: Image) => item.getImagePath())
  }

  @action
  setStatus(status?: Status) {
    this.status = status ?? this.status
  }

  getStatus(): Status {
    return this.status
  }

  isApproved(): boolean {
    return this.status === Status.APPROVED
  }

  isRejected(): boolean {
    return this.status === Status.REJECTED
  }

  isPending(): boolean {
    return this.status === Status.PENDING
  }
}
