import {observable} from 'mobx'
import {Transaction} from './transaction'
import {Worlds} from './worlds'

export class WorldsTransaction extends Transaction {
  @observable readonly worldId: number
  @observable readonly world: Worlds

  constructor(transactionWorlds: any) {
    super(transactionWorlds)

    this.worldId = transactionWorlds?.world_id
    this.world = new Worlds(transactionWorlds?.world)
  }
}
