/**
 *
 * ExperienceCard
 *
 */
import {
  Card,
  CardActions,
  CardContent,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {AccessTime} from '@material-ui/icons'
import {Experience} from 'app/models'
import {Status} from 'app/types'
import {observer} from 'mobx-react'
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {Text, Button} from '../index'

interface ExperienceCardProps {
  /**
   * details of the experience
   */
  experience: Experience

  /**
   * redirect to edit page
   */
  handleEdit: () => void
}

const styles = () => ({
  root: {
    // width: 360,
  },
  icon: {
    fontSize: 20,
    marginBottom: 4,
    paddingRight: 2,
  },
  action: {
    justifyContent: 'space-between',
    padding: 16,
    paddingBottom: 8,
  },
  status: (props) => ({
    color:
      props.experience.getStatus() === Status.APPROVED
        ? 'green'
        : props.experience.getStatus() === Status.PENDING
        ? '#FF9100'
        : 'red',
  }),
})

export const ExperienceCard = memo(
  withStyles(styles)(
    observer(
      ({
        classes,
        experience,
        handleEdit,
      }: ExperienceCardProps & WithStyles<typeof styles>) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {t, i18n} = useTranslation()
        return (
          <>
            <Card className={classes.root}>
              <CardContent>
                <Text
                  style={{height: '65px'}}
                  text={experience.getTitle()}
                  variant='h5'
                />
                <Typography color='textSecondary' variant='subtitle1'>
                  <AccessTime className={classes.icon} />
                  {experience.duration}
                </Typography>
              </CardContent>
              <CardActions className={classes.action}>
                <Text
                  tx={
                    experience.getStatus() === Status.APPROVED
                      ? 'webinarsContainer.approved'
                      : experience.getStatus() === Status.PENDING
                      ? 'webinarsContainer.inReview'
                      : 'webinarsContainer.rejected'
                  }
                  variant='button'
                  className={classes.status}
                />
                {experience.getStatus() == Status.APPROVED && (
                  <Button
                    color='primary'
                    onClick={() =>
                      window
                        .open(
                          `https://school.edvolve.in/experience/${experience.getNumericId()}`,
                          '_blank',
                        )
                        ?.focus()
                    }
                  >
                    {t('profileContainer.preview')}
                  </Button>
                )}
                <Button color='primary' onClick={handleEdit}>
                  Edit Content
                </Button>
              </CardActions>
            </Card>
          </>
        )
      },
    ),
  ),
)
