import {Status} from 'app/types'
import {action, observable} from 'mobx'
import {Model} from './model'
import {Timestamp} from './timestamp'

export class Bankdetails extends Model {
  @observable readonly benificiaryName: string
  @observable readonly accountNumber: string
  @observable readonly ifscCode: string

  constructor(bankdetails: any) {
    super(bankdetails)
    this.accountNumber = bankdetails?.account_number ?? ''
    this.benificiaryName = bankdetails?.benificiary_name ?? ''
    this.ifscCode = bankdetails?.ifsc_code ?? ''
  }
}
