/**
 *
 * Mentor
 *
 */
import React, {memo} from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {observer} from 'mobx-react'
import _ from 'lodash'
import {Text} from '../Text'
import {User} from '../../models'
import {SubjectComponent} from '../Subject'
import {Spacing} from '../Spacing'

interface Props {
  /**
   * User Model
   */
  mentor: User
}

const styles = (theme: Theme) => ({
  avatar: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
})

export const MentorComponent = memo(
  withStyles(styles)(
    observer((props: Props & WithStyles<typeof styles>) => {
      const {classes, mentor} = props

      return (
        <Card>
          <CardHeader
            avatar={
              <Avatar
                alt={mentor.getFirstName()}
                // src='/static/images/avatar/1.jpg'
                className={classes.avatar}
              />
            }
            title={`${mentor.getFirstName()} ${mentor.getLastName()}`}
          />
          <Divider />
          <CardContent>
            <Text variant='body2' color='textSecondary'>
              {mentor.getAboutMe()}
            </Text>
            <Spacing marginTop={2}>
              {_.map(mentor.getSubjects().items, (subject) => (
                <Box mt={1}>
                  <SubjectComponent key={subject.getId()} subject={subject} />
                </Box>
              ))}
            </Spacing>
            {/*
          <Grid container justify='flex-end'>
            <Box mt={2}>
              <Button
                variant='contained'
                color='secondary'
                startIcon={<InfoIcon />}
              >
                {t('common.about')}
              </Button>
            </Box>
          </Grid>
          */}
          </CardContent>
        </Card>
      )
    }),
  ),
)
