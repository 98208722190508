/**
 *
 * InstitutionStructure
 *
 */
import React, {memo} from 'react'
import {Box, Paper} from '@material-ui/core'
import {Text} from '../Text'

interface Props {
  /**
   * Classroom
   */
  classroom: string

  /**
   * School name
   */
  school: string
}

export const InstitutionStructureComponent = memo((props: Props) => {
  const {classroom, school} = props

  return (
    <Paper>
      <Box padding={2}>
        <Text
          bold
          uppercase
          variant='body2'
          tx='institutionStructureContainer.institution'
          color='secondary'
        />
        <Text variant='body2' text={school} color='textSecondary' />
        <Box pt={2}>
          <Text
            bold
            uppercase
            variant='body2'
            tx='institutionStructureContainer.classroom'
            color='secondary'
          />
          <Text variant='body2' text={classroom} color='textSecondary' />
        </Box>
      </Box>
    </Paper>
  )
})
