import {Model} from './model'
import {observable} from 'mobx'

export class Grade extends Model {
  @observable private readonly name: string

  constructor(grade: any) {
    super(grade)
    this.name = grade?.grade ?? ''
  }

  getName(): string {
    return this.name
  }
}
