/**
 *
 * Subject
 *
 */
import React, {memo} from 'react'
import {Box, Paper} from '@material-ui/core'
import {Text} from '../Text'
import {Subject} from '../../models'

export const SubjectComponent = memo(({subject}: {subject: Subject}) => {
  return (
    <Paper>
      <Box padding={2}>
        <Text
          bold
          uppercase
          variant='body2'
          tx='subjectContainer.subjectName'
          color='secondary'
        />
        <Text variant='body2' text={subject.getName()} color='textSecondary' />
        <Box pt={2}>
          <Text
            bold
            uppercase
            variant='body2'
            tx='subjectContainer.description'
            color='secondary'
          />
          <Text
            variant='body2'
            text={subject.getDescription()}
            color='textSecondary'
          />
        </Box>
      </Box>
    </Paper>
  )
})
