/**
 *
 * Button
 *
 */
import React, {memo} from 'react'
import {
  Button as MUIButton,
  ButtonProps,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {Spacing, SpacingProps} from '../Spacing'
import {Text, TextProps} from '../Text'

interface Props extends ButtonProps {
  /**
   * All the margins and padding for the component
   */
  spacingProps?: SpacingProps

  /**
   * Button's height
   */
  height?: number

  /**
   * Button's width
   */
  width?: number

  /**
   * Button's max height
   */
  maxHeight?: number

  /**
   * Button's max width
   */
  maxWidth?: number

  /**
   * Button's min height
   */
  minHeight?: number

  /**
   * Button's min width
   */
  minWidth?: number

  /**
   * Text to be displayed on the button
   */
  text?: TextProps

  /**
   * button is grayed and not clickable when disabled is true
   */
  disabled?: boolean
}

const styles = (theme: Theme) => ({
  button: {
    height: (props) => props.height,
    maxHeight: (props) => props.maxHeight,
    minHeight: (props) => props.minHeight,
    width: (props) => props.width,
    maxWidth: (props) => props.maxWidth,
    minWidth: (props) => props.minWidth,
  },
})

export const Button: React.FunctionComponent<Props> = memo(
  withStyles(styles)((props: Props & WithStyles<typeof styles>) => {
    const {children, spacingProps, classes, text, ...rest} = props

    return (
      <Spacing {...spacingProps}>
        <MUIButton color='secondary' className={classes.button} {...rest}>
          {!!text && <Text {...text} />}
          {!text && children}
        </MUIButton>
      </Spacing>
    )
  }),
)
