import React, {useContext} from 'react'
import {NotificationsHelper} from '../../utils/helpers'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

export const NotificationContext = React.createContext<NotificationsHelper>(
  new NotificationsHelper(undefined, undefined),
)

export const NotificationsProvider = ({children}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const notifier = new NotificationsHelper(enqueueSnackbar, t)

  return (
    <NotificationContext.Provider value={notifier}>
      {children}
    </NotificationContext.Provider>
  )
}

export const withNotifier = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  return class WithNotifier extends React.Component<
    P & {notifier: NotificationsHelper},
    any
  > {
    render() {
      return (
        <NotificationContext.Consumer>
          {(value) => <Component {...this.props} notifier={value} />}
        </NotificationContext.Consumer>
      )
    }
  }
}

export const useNotifier = (): NotificationsHelper => {
  return useContext(NotificationContext)
}
