/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react'
import {Helmet} from 'react-helmet-async'
import {BrowserRouter, Route, Switch} from 'react-router-dom'

import {GlobalStyle} from 'styles/global-styles'

import {Dashboard, Login, Signup} from './containers'
import {NotFoundPage} from './components'
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/core/styles'
import {green, red} from '@material-ui/core/colors'
import {Routes} from './routes'
import {NotificationsProvider} from './contexts'
import {PaletteColor} from '@material-ui/core/styles/createPalette'

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#0F0F43',
    },
    secondary: {main: '#1D9929', contrastText: '#fff'},
    error: red,
    success: green,
    white: {main: '#fff', contrastText: '#000'},
  },
  typography: {
    h1: {},
  },
}
const theme = responsiveFontSizes(createMuiTheme(themeOptions))

const getColor = (color: Partial<PaletteColor>): PaletteColor => ({
  main: '#fff',
  light: '#fff',
  dark: '#fff',
  contrastText: '#000',
  ...color,
})

const setMainColor = (main: string) => ({main})

const colors: {[x: string]: PaletteColor} = {
  white: getColor(setMainColor('#fff')),
}

export const useColors = () => colors

export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate='%s - Web Portal' defaultTitle='Unishaala'>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
        <meta name='description' content='Unishaala web portal' />
      </Helmet>
      <ThemeProvider theme={theme}>
        <NotificationsProvider>
          <Switch>
            <Route exact path={Routes.DEFAULT} component={Login} />
            <Route exact path={Routes.LOGIN} component={Login} />
            <Route exact path={Routes.SIGN_UP} component={Signup} />
            <Route path={Routes.DASHBOARD} component={Dashboard} />
            <Route component={NotFoundPage} />
          </Switch>
        </NotificationsProvider>
        <GlobalStyle />
      </ThemeProvider>
    </BrowserRouter>
  )
}
