/**
 *
 * Asynchronously loads the component for Webinar
 *
 */

import {lazyLoad} from 'utils/loadable'

export const Webinars = lazyLoad(
  () => import('./index'),
  (module) => module.Webinars,
)
