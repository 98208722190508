import {AccountStoreImpl, AppStoreImpl} from '..'
import {IStore} from '../store'
import {action, observable} from 'mobx'

export enum StoreType {
  ACCOUNT = 'accountStore',
  APP = 'appStore',
}

export interface Stores extends IStore {
  /**
   * Contains account specific details such as jwt, profile etc.
   */
  accountStore: AccountStoreImpl

  /**
   * Contains app specific details such as schools, tutors, courses etc.
   */
  appStore: AppStoreImpl
}

export class StoresImpl implements Stores {
  @observable accountStore: AccountStoreImpl
  @observable appStore: AppStoreImpl

  constructor() {
    this.accountStore = new AccountStoreImpl()
    this.appStore = new AppStoreImpl()
  }

  @action
  resetStore = (): void => {
    // All the stores must be reset here
    this.accountStore.resetStore()
    this.appStore.resetStore()
  }
}

export const stores: Stores = new StoresImpl()
