import {action, observable} from 'mobx'
import AsyncStorage from '@react-native-community/async-storage'
import {Bankdetails, User} from '../../models'
import {AccountStore} from './account.store'
import {StoreImpl} from '../store/store.impl'

export class AccountStoreImpl extends StoreImpl implements AccountStore {
  static TOKEN = 'token'
  static USER_ID = 'user_id'
  static USER_UUID = 'user_uuid'
  // @ts-ignore
  private token?: string
  private userId?: string
  private userUuid?: string
  @observable private user?: User
  @observable private bankDetails?: Bankdetails

  constructor() {
    super()
    this.loadToken()
    this.loadUserId()
    this.loadUserUuid()
  }

  @action
  async resetStore() {
    this.token = ''
    this.userId = ''
    this.userUuid = ''
    this.user = undefined
    this.bankDetails = undefined
    await AsyncStorage.removeItem(AccountStoreImpl.TOKEN)
    await AsyncStorage.removeItem(AccountStoreImpl.USER_ID)
  }

  @action
  private async loadToken() {
    const token =
      (await AsyncStorage.getItem(AccountStoreImpl.TOKEN)) ?? undefined
    this.token = token ? JSON.parse(token) : ''
  }

  @action
  private async loadUserId() {
    const userId = await AsyncStorage.getItem(AccountStoreImpl.USER_ID)
    this.userId = userId ? JSON.parse(userId) : undefined
  }

  @action
  private async loadUserUuid() {
    const userUuid = await AsyncStorage.getItem(AccountStoreImpl.USER_UUID)
    this.userUuid = userUuid ? JSON.parse(userUuid) : undefined
  }

  async setToken(token: string) {
    this.token = token
    await AsyncStorage.setItem(AccountStoreImpl.TOKEN, JSON.stringify(token))
  }

  async setUserId(id: string) {
    this.userId = id
    await AsyncStorage.setItem(AccountStoreImpl.USER_ID, JSON.stringify(id))
  }

  async setUserUuid(uuid: string) {
    this.userUuid = uuid
    await AsyncStorage.setItem(AccountStoreImpl.USER_UUID, JSON.stringify(uuid))
  }

  getToken() {
    return this.token
  }

  @action
  setUser(user: User) {
    this.user = user
  }

  getUserId(): string | undefined {
    return this.userId
  }

  getUserUuid(): string | undefined {
    return this.userUuid
  }

  getUser(): User | undefined {
    return this.user
  }

  userExists(): boolean {
    return !!this.token && !!this.userId
  }

  @action
  setBankDetails(bankDetails: Bankdetails) {
    this.bankDetails = bankDetails
  }

  getBankDetails(): Bankdetails | undefined {
    return this.bankDetails
  }
}
