import React, { useState, memo } from 'react';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';

interface Props {
    images: string[]
    actorName: string
}

export const ImageSlider = memo((props: Props) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? props.images.length - 1 : prevIndex - 1
        );
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: "550px", position: "relative" }}>
            <SwipeableViews index={currentImageIndex} onChangeIndex={setCurrentImageIndex}>
                {props.images.map((image, index) => (
                    <div key={index} style={{ height: '100%', display:"flex", alignItems:"center" }}>
                        <img
                            src={image}
                            alt={props.actorName}
                            style={{ width: '100%', maxWidth:"550px"}}
                        />
                    </div>
                ))}
            </SwipeableViews>
            {
                props.images.length > 1 && <ArrowBack onClick={prevImage} style={{ cursor: 'pointer', position: "absolute", left: 0, background: "#fff", borderRadius: "50%" }} />
            }
            {
                props.images.length > 1 && <ArrowForward onClick={nextImage} style={{ cursor: 'pointer', position: "absolute", right: 0, background: "#fff", borderRadius: "50%" }} />
            }

        </div>
    );
})


