import _ from 'lodash'
import {action, observable} from 'mobx'
import {Model} from './model'
import {Status} from 'app/types'
import {Subject, Language, GradeList} from './'

export class Worlds extends Model {
  @observable private readonly title: string
  @observable private readonly description: string
  @observable readonly primarySubject: Subject
  @observable readonly secondarySubject: Subject
  @observable readonly guidelines: string
  @observable readonly outcome: string
  @observable readonly highlight: string
  @observable readonly time_commitment: string
  @observable readonly language: Language
  @observable readonly grades: GradeList
  @observable readonly groupSize: number
  @observable readonly requirement: string
  @observable readonly image: string
  @observable readonly videoUrl: string 
  @observable readonly fee: number
  @observable private status: Status
  @observable readonly mentorName: string
  @observable readonly mentorId: string
  @observable readonly free: number
  @observable readonly freeTrial: number
  @observable readonly freeTrialDays: number
  @observable readonly membersCount: number
  @observable readonly worldStatus: number
  @observable readonly createdAt: string

  constructor(worlds: any) {
    super(worlds)

    this.title = worlds?.title ?? ''
    this.description = worlds?.description ?? ''
    this.primarySubject = new Subject(worlds?.primary_subject)
    this.secondarySubject = new Subject(worlds?.secondary_subject)
    this.guidelines = worlds?.guidelines ?? ''
    this.outcome = worlds?.outcome ?? ''
    this.highlight = worlds?.tagline ?? ''
    this.time_commitment = worlds?.time_commitment ?? ''
    this.language = new Language(worlds?.language)
    this.grades = new GradeList(worlds?.grades)
    this.requirement = worlds?.requirement ?? ''
    this.image = worlds?.image ?? ''
    this.videoUrl = worlds?.video_url ?? ''
    this.fee = worlds?.fee
    this.status = worlds?.status ?? Status.ALL
    this.groupSize = worlds?.group_size ?? 5
    this.mentorName = worlds?.uuid.user.first_name ?? ''
    this.mentorId = worlds?.uuid?.uuid ?? ''
    this.free = worlds?.free_world ?? 0
    this.freeTrial = worlds?.free_trial ?? 0
    this.freeTrialDays = worlds?.free_trial_days ?? 0
    this.membersCount = worlds?.active_subscription_count ?? 0
    this.worldStatus = worlds?.status ?? 0
    this.createdAt = worlds?.created_at ?? ''
  }

  getTitle(): string {
    return this.title
  }

  getDescription(): string {
    return this.description
  }

  @action
  setStatus(status?: Status) {
    this.status = status ?? this.status
  }

  getStatus(): Status {
    return this.status
  }

  isApproved(): boolean {
    return this.status === Status.APPROVED
  }

  isRejected(): boolean {
    return this.status === Status.REJECTED
  }

  isPending(): boolean {
    return this.status === Status.PENDING
  }
}
