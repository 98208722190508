const data = [
  {
    id: 1,
    language: 'English',
    created_at: '2021-01-29 11:09:04',
    updated_at: '2021-01-29 11:09:04',
  },
  {
    id: 2,
    language: 'Hindi',
    created_at: '2021-01-29 11:09:09',
    updated_at: '2021-01-29 11:09:09',
  },
]

export default data
