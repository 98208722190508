import { observable } from 'mobx'
import { City, SubjectList, UserType } from './index'
import { Model } from './model'

export class User extends Model {
  static DEFAULT_AVATAR =
    'https://flyinryanhawks.org/wp-content/uploads/2016/08/profile-placeholder.png'
  static DEFAULT_COVER_IMAGE =
    'https://api.edvolve.in/media/background-mentor.jpg'
  @observable private readonly firstName: string
  @observable private readonly lastName: string
  @observable private readonly email: string
  @observable private readonly videoLink: string
  @observable private readonly dob: number | undefined
  @observable private readonly mobileNumber: string
  @observable readonly city: City
  @observable private readonly avatarUrl: string
  @observable private readonly coverImage: string
  @observable private readonly oneLiner: string
  @observable private readonly aboutMe: string
  @observable private readonly uuid: string
  @observable private readonly status: boolean
  @observable private readonly type: UserType
  @observable private readonly subjects: SubjectList

  constructor(user: any) {
    super(user)
    this.firstName = user?.first_name ?? ''
    this.lastName = user?.last_name ?? ''
    this.email = user?.email ?? ''
    this.videoLink = user.intro_video_link ?? ''
    this.dob = user?.dob ?? ''
    this.mobileNumber = user?.mobile_number ?? ''
    this.city = new City(user?.city)
    this.avatarUrl = user?.avatar_url ?? ''
    this.coverImage = user?.cover_img ?? ''
    this.oneLiner = user?.one_liner ?? ''
    this.aboutMe = user?.about_me ?? ''
    this.uuid = user?.uuid?.uuid ?? ''
    this.status = user?.active === 1 ? true : false
    this.type = user?.user_type ?? UserType.MENTOR
    this.subjects = new SubjectList(user?.subjects)
  }

  getUUID(): string {
    return this.uuid
  }

  exists() {
    return !!this.getId()
  }

  getUserName = (): string => {
    return `${this.firstName} ${this.lastName}`
  }

  getFirstName = (): string => {
    return this.firstName
  }

  getLastName = (): string => {
    return this.lastName
  }

  getEmail = (): string => {
    return this.email
  }

  getVideoLink = (): string => {
    return this.videoLink
  }

  getAboutMe = (): string => {
    return this.aboutMe
  }

  getPhone = (): string => {
    return this.mobileNumber
  }

  getAvatar = (): string => {
    return this.avatarUrl || User.DEFAULT_AVATAR
  }

  getCoverImage = (): string => {
    return this.coverImage || User.DEFAULT_COVER_IMAGE
  }

  isAdmin = (): boolean => {
    return this.type === UserType.ADMIN
  }

  isMentor = (): boolean => {
    return this.type === UserType.MENTOR
  }

  getSubjects = (): SubjectList => {
    return this.subjects
  }

  getOneLiner = (): string => {
    return this.oneLiner
  }

  getStatus = (): boolean => {
    return this.status
  }
}
